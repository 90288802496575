/** 
 * 
 * N values compare
 * 
 * Updated: 2020-12-26
 */


import { Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Bar, ComposedChart, ReferenceLine, Legend, Tooltip, XAxis, YAxis } from "recharts";
import BoxKpi from '../ui/ui.box.kpi';

class ChartsValuesCompare extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            width: props.width,
            height: props.height
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    handleChange(event) {
    }

    /*
                            dataKey={this.state.revenueYTD_chartData.dataKey}
                            dataValueKey={this.state.revenueYTD_chartData.dataValueKey}
                            dataValueColor={this.state.revenueYTD_chartData.dataValueColor}
                            dataRefs={this.state.revenueYTD_chartData.dataRefs}    
    
    */
    render() {
        return (
            <BoxKpi>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>{this.props.data.headerText}</Typography>
                        <Typography variant="h4" gutterBottom>{this.props.data.mainValue}</Typography>
                        <Typography variant="body2" gutterBottom>{this.props.data.secondHeaderText + ' ' + this.props.data.secondValue}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ComposedChart
                            viewBox="0 0 {this.state.width} {this.state.height}"
                            preserveAspectRatio="xMidYMid meet"
                            layout="vertical"
                            width={this.state.width}
                            height={this.state.height}
                            data={this.props.data.data}
                        >
                            {/* <CartesianGrid stroke="#f5f5f5" hide /> */}
                            <Legend payload={this.props.data.legend} />
                            <Tooltip />
                            <XAxis type="number" hide domain={[0, this.props.data.dataMax + 1000]} tickFormatter={tick => { return Math.round(tick / 1000).toString() + "K"; }} allowDataOverflow={true} />
                            <YAxis type="category" hide dataKey={this.props.data.dataKey} />
                            <Bar dataKey={this.props.data.dataValueKey} barSize={30} fill={this.props.data.dataValueColor} background={{ fill: '#eee' }} />
                            {
                                this.props.data.dataRefs.map(
                                    (itemData, idx) => (
                                        <ReferenceLine key={idx} x={itemData.value} stroke={itemData.color ? itemData.color : "#ff0000"} strokeWidth={2} />
                                    )
                                )
                            }
                        </ComposedChart>
                    </Grid>
                </Grid>
            </BoxKpi>
        );
    }

}

export default ChartsValuesCompare;