/**
 * 
 * Updated: 2020-10-19
 */

import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import { /* Box, */ Box, Grid, Modal, Typography } from '@material-ui/core';
import blueGrey from "@material-ui/core/colors/blueGrey";

import BlockHeader from "../components/ui/BlockHeader";

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    orderHolder: {
        position: 'fixed', padding: '1em', backgroundColor: blueGrey[50],
        top: 0, bottom: 0, left: 0, right: 0,
        maxHeight: "550px", minWidth: "380px", maxWidth: "480px",
        margin: "auto",
    },
    orderContent: {
        position: 'fixed', padding: '1em', backgroundColor: 'white',
        top: 0, bottom: 0, left: 0, right: 0,
        maxHeight: "550px", minWidth: "380px", maxWidth: "480px",
        margin: "auto",
    }
});


class BookingOrderDetails extends Component {

    infoFieldsList = ['awb', 'reservDate', 'code', 'origin', 'destination',
        'goods',
        'pcs', 'weight', 'cbm', 'fare',
        'flightDate', 'executed'];

    constructor(props) {
        super();

        this.state = {
            ucode: props.ucode,
            names: []
        };

        this.handleBookingOrderDetailsClose = this.handleBookingOrderDetailsClose.bind(this);
    }

    /** */
    componentDidMount() {
        // if (this.props.onPageChanged) {
        //   this.props.onPageChanged();
        // }

        // this.loadDealsHistory();

        this.setState({ names: [...this.infoFieldsList] });
    }

    handleBookingOrderDetailsClose(e) {
        var val = { showOrderDetails: false };
        if (this.props.onValuesChanged) {
            this.props.onValuesChanged(val);
        }
    }


    render() {
        const { classes } = this.props;

        return (
            <>



                <Modal aria-labelledby="order-title"
                    aria-describedby="order-description"
                    open={this.props.showOrderDetails}
                    onClose={this.handleBookingOrderDetailsClose}
                >
                    <div className={classes.orderHolder} >
                        <div id="order-description" className={classes.orderContent} >
                            <BlockHeader key="order-header" headertext={"Details"}></BlockHeader>

                            <Grid container direction="row" spacing={2}>
                                {
                                    this.state.names.map((name, i) => (
                                        <>
                                            <Grid item xs={3} style={{ background: blueGrey[300] }} >
                                                <Typography key={name + "_n"} gutterBottom variant="body2" align="right">{name}</Typography>
                                            </Grid>
                                            <Grid item xs={9} >
                                                <Box key={"box-" + i} borderBottom="1px solid #607d8b">
                                                    <Typography key={name + "_v"} gutterBottom variant="body2">{this.props[name]}</Typography>
                                                </Box>
                                            </Grid>
                                        </>
                                    ))
                                }
                            </Grid>
                        </div>
                    </div>
                </Modal>


            </>
        );
    }

}

export default withStyles(styles, { withTheme: true })(BookingOrderDetails);