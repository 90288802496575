/** 
 * 
 * Updated: 2020-10-06
 */


import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import convert from 'xml-js';
import requestPost from '../service/api.service';
import { Grid } from "@material-ui/core";

import UploadInitSteps from "../components/parameters/user.upload.initsteps";
import UserUploadHistoryList from "../components/parameters/user.upload.historylist";

const styles = (theme) => ({
});


class UserUploadHistory extends Component {

    constructor(props) {
        super();
        this.state = {
            loading: false,
            response: "",
            error: false,
            errorMessage: "",
            showMessageSuccess: false,
            message: "",
            loguuid: "",
            stepsInfoList: [],
            historicUploadsList: []
        };

        this.getDataCallback = this.getDataCallback.bind(this);
        this.getDataDetailsCallback = this.getDataDetailsCallback.bind(this);
        this.handleLogUuidChanged = this.handleLogUuidChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.onPageChanged) {
            this.props.onPageChanged();
        }

        this.getData();
    }

    /** */
    getData() {
        var xml = '<XMLRequest cmd="TenantInitUtil.getHistoricData" />';
        this.setState({ loading: true },
            () => {
                requestPost('', '', xml, this.getDataCallback, this.props.appBaseURL)
            });
    }

    /** */
    getDataCallback(response) {
        var respJson = {};
        if (response?.length > 0) {
            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }

        let historicUploadsList = (respJson?.XMLResponse?.historicData?.Row) ? this.updateHistoricUploadsList(respJson.XMLResponse.historicData.Row) : [];
        console.log("historicUploadsList: ", historicUploadsList);

        this.setState({ historicUploadsList: historicUploadsList, loading: false },
            () => {
                if (this.state.historicUploadsList?.length > 0) {
                    let uuid = this.state.historicUploadsList[0]._attributes.uuid;
                    this.setState({ loguuid: uuid },
                        () => { this.getDataDetails(uuid); }
                    );
                }
            });
    }

    /** */
    updateHistoricUploadsList(nodeData) {
        var historicUploadsList = [];
        if (nodeData) {
            if (Array.isArray(nodeData)) {
                historicUploadsList = [...nodeData];
            } else {
                historicUploadsList.push(nodeData);
            }
        }
        return historicUploadsList;
    }


    /** */
    handleLogUuidChanged(uuid) {
        this.setState({ loguuid: uuid },
            () => { this.getDataDetails(uuid); }
        );
    }

    /** */
    getDataDetails(uuid) {
        var xml = '<XMLRequest cmd="TenantInitUtil.getHistoricData" uuid="' + uuid + '" />';
        this.setState({ loading: true },
            () => {
                requestPost('', '', xml, this.getDataDetailsCallback, this.props.appBaseURL)
            });
    }

    /** */
    getDataDetailsCallback(response) {
        var respJson = {};
        if (response?.length > 0) {
            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }
        let stepsInfoList = !!respJson?.XMLResponse?.Data ? this.updateStepsInfo(respJson.XMLResponse.Data.Row) : [];
        this.setState({ stepsInfoList: stepsInfoList, loading: false },
            () => { });
    }

    /** */
    updateStepsInfo(nodeData) {
        var stepsInfoList = [];
        if (nodeData) {
            if (Array.isArray(nodeData)) {
                stepsInfoList = [...nodeData];
            } else {
                stepsInfoList.push(nodeData);
            }
        }
        return stepsInfoList;
    }

    /**  */
    handleSubChanged = (values) => {
        this.setState({ ...values }, () => {
        });
    };


    /** */
    render() {

        return (
            <Container maxWidth={false} >
                <div>
                    <h2>Uploads History</h2>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <UserUploadHistoryList handleLogUuidChanged={this.handleLogUuidChanged} historicUploadsList={this.state.historicUploadsList}
                            appDownloadURL={this.props.appDownloadURL} />
                    </Grid>
                    <Grid item container xs={12} sm={7}>
                        <UploadInitSteps appBaseURL={this.props.appBaseURL} appUploadURL={this.props.appUploadURL} stepsInfoList={this.state.stepsInfoList} />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles, { withTheme: true })(UserUploadHistory);