/**
 * e-booking history
 * Updated: 2020-10-17
 */

import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

import BlockHeader from "../components/ui/BlockHeader";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Paper, Grid } from '@material-ui/core';

import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';

// import grey from "@material-ui/core/colors/grey";
import lightGreen from "@material-ui/core/colors/lightGreen";
// import deepOrange from "@material-ui/core/colors/deepOrange";

import { getDealsHistory } from '../service/booking.service';
import BookingOrderDetails from './booking.order.details';
import viewdetails from '../assets/images/viewdetails.svg';
import blueGrey from "@material-ui/core/colors/blueGrey";

const styles = (theme) => ({
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: blueGrey[400],
      "& > .MuiTableCell-root": {
        color: "yellow"
      }
    }
  },
  selected: {}
});

class BookingHistory extends Component {

  constructor(props) {
    super();

    this.state = {
      dealsList: [],
      ucode: props.ucode,
      showOrderDetails: false,
      activerow: {},
      selectedID: -1
    };

    this.callbackBookingHistory = this.callbackBookingHistory.bind(this);
    this.onDetails = this.onDetails.bind(this);
    this.onValuesSubChanged = this.onValuesSubChanged.bind(this);
    this.setSelectedID = this.setSelectedID.bind(this);
  }

  /** */
  componentDidMount() {
    if (this.props.onPageChanged) {
      this.props.onPageChanged();
    }

    this.loadDealsHistory();
  }

  /** */
  loadDealsHistory() {
    var data = { user: this.props.ucode };
    getDealsHistory(data, this.callbackBookingHistory, this.props.appBaseURL, this.props.ucode);
  }

  /** */
  callbackBookingHistory(response) {
    console.log('callbackBookingHistory: ', response);
    var dealsList = [];
    if (response?.XMLResponse?.Row) {
      dealsList = response.XMLResponse.Row.map((row) => {
        return row._attributes;
      });
    };

    dealsList.sort((a, b) => { return b.id * 1 - a.id * 1; });

    this.setState({ dealsList: dealsList });
  }

  /** */
  onValuesSubChanged(value) {
    this.setState(value);
  }

  /** */
  onDetails(row) {
    console.log("row details:", row);
    this.setState({
      showOrderDetails: true,
      activerow: row
    });
  }

  /** */
  setSelectedID(id) {
    this.setState({ selectedID: id });
  }

  /** */
  render() {

    const { classes } = this.props;

    const roundToN = (num, precision) => {
      return +(Math.round(num + "e+" + precision) + "e-" + precision);
    };


    return (
      <>
        <BlockHeader headertext={"Booking history"}></BlockHeader>
        <div style={{ textAlign: "center" }} >In progerss</div>
        <Grid item xs={12} sm={8}>
          {
            (this.state.showOrderDetails ?
              <BookingOrderDetails key="BookingOrderDetails" onValuesChanged={this.onValuesSubChanged}
                {...this.state.activerow} {...this.state.activerow.value}
                showOrderDetails={this.state.showOrderDetails} /> : <div key="dummy"></div>)
          }
        </Grid>

        <TableContainer component={Paper}>
          <Table aria-label="cargoes list" size="small">
            <TableHead>
              <TableRow key={"h1"}>
                <TableCell className={classes.theader} rowSpan={2}></TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Origin</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Destination</TableCell>
                <TableCell className={classes.theader} colSpan={3} align="center">Reservation/Deal</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>AWB</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Departure</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Goods</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Code</TableCell>
                <TableCell className={classes.theader} colSpan={3} align="center">Gross</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Rate $/kg</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Fare $</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Status</TableCell>
                <TableCell className={classes.theader} rowSpan={2}>Executed</TableCell>
              </TableRow>
              <TableRow key={"h2"}>
                <TableCell className={classes.theader}>Reservation</TableCell>
                <TableCell className={classes.theader}>Deal Date</TableCell>
                <TableCell className={classes.theader}>#</TableCell>
                <TableCell className={classes.theader}>Pieces</TableCell>
                <TableCell className={classes.theader}>Volume (cbm) </TableCell>
                <TableCell className={classes.theader}>Weight (kg)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.dealsList.map((row) => (
                  <TableRow key={row.id} hover className={classes.tableRow}
                    onClick={() => {
                      this.setSelectedID(row.id);
                    }}
                    selected={this.state.selectedID === row.id}
                    classes={{ selected: classes.selected }}>
                    <TableCell align="left">
                      <IconButton aria-label="details" style={{ color: lightGreen[700]  }}
                        onClick={() => this.onDetails(row)}>
                        <img alt="Details" style={{ height: '0.8em', maxHeight: '1.2em', verticalAlign: 'text-bottom', color: lightGreen[700] }}
                          src={viewdetails} />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">{row.origin}</TableCell>
                    <TableCell align="left">{row.destination}</TableCell>
                    <TableCell align="left">{row.reservDate}</TableCell>
                    <TableCell align="left">{row.dealDate}</TableCell>
                    <TableCell align="left">{row.dealIssuedId}</TableCell>
                    <TableCell align="left">{row.awb}</TableCell>
                    <TableCell align="left">{row.flightDate}</TableCell>
                    <TableCell align="left">{row.goods}</TableCell>
                    <TableCell align="left">{row.code}</TableCell>
                    <TableCell align="left">{row.pcs}</TableCell>
                    <TableCell align="left">{row.weight}</TableCell>
                    <TableCell align="left">{row.cbm}</TableCell>
                    <TableCell align="left">{roundToN((row.fare * 1) / (row.weight * 1), 2)}</TableCell>
                    <TableCell align="left">{row.fare}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">{(row.executed === 'true') ? (<DoneAllOutlinedIcon fontSize="small" style={{ color: lightGreen[400] }} />) : (<></>)}</TableCell>
                  </TableRow>
                ))}

            </TableBody>
          </Table>
        </TableContainer >
      </>
    );
  }

}

export default withStyles(styles, { withTheme: true })(BookingHistory);
