/** 
 * 
 * Updated: 2020-10-06
 */

import React, { Component } from "react";

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';

import Paper from '@material-ui/core/Paper';

import { withStyles } from "@material-ui/core/styles";

import BlockHeader from "../../components/ui/BlockHeader";

import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import grey from "@material-ui/core/colors/grey";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
import { green } from '@material-ui/core/colors';
//import deepOrange from "@material-ui/core/colors/deepOrange";
import lightBlue from "@material-ui/core/colors/lightBlue";
import red from "@material-ui/core/colors/red";
import indigo from "@material-ui/core/colors/indigo";
import { Checkbox } from "@material-ui/core";


const styles = (theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    denseRow: {
        height: '0.8em',
        padding: '1px 1px 1px 3px',
        verticalAlign: 'baseline',
    }
});

class UploadInitSteps extends Component {

    constructor(props) {
        super();
        this.state = {
            stepsInfoList: props.stepsInfoList,
            checkedShowFullText: false
        };
        //this.handleChange = this.handleChange.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
    }

    handleCheckBox(e) {
        console.log("handleCheckBox: ", e.target.name, ' ', e.target.checked);
        this.setState({ [e.target.name]: e.target.checked });
    }

    render() {

        const { classes } = this.props;

        const statusSwitch = (status) => {
            if (!status) status = '';
            switch (status.toString().toLowerCase()) {
                case "active": return <PlayCircleFilledWhiteOutlinedIcon style={{ color: lightBlue[500] }} />;
                case "failure": return <ErrorOutlineOutlinedIcon style={{ color: red[500] }} />;
                case "pending": return <HourglassEmptyOutlinedIcon style={{ color: indigo[500] }} />;
                case "success": return <CheckCircleOutlineOutlinedIcon style={{ color: lightGreen[500] }} />;
                case "warning": return <ReportProblemOutlinedIcon style={{ color: orange[400] }} />;
                default: return <HelpOutlineOutlinedIcon style={{ color: grey[500] }} />;
            }

        };

        const cropText = (text) => {
            if (text?.length > 128 && !this.state.checkedShowFullText) {
                return text.substring(0, 128) + '...';
            }
            return text;

        };

        return (
            <>
                <BlockHeader headertext={"Step Details"}></BlockHeader>
                <TableContainer component={Paper} style={{ maxHeight: 580 }}>

                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Step</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell> <div style={{display: 'flex'}}>
                                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}><span>Details</span></div>
                                    <div style={{ marginLeft: "auto" }}>
                                        <span>Show full </span>
                                        <Checkbox
                                            checked={this.state.checkedShowFullText}
                                            onChange={this.handleCheckBox}
                                            name="checkedShowFullText"
                                            style={{color: green[400]}} />
                                    </div>
                                    </div>
                                </TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (Array.isArray(this.props.stepsInfoList) ? this.props.stepsInfoList : [].concat(this.props.stepsInfoList)).map((row, i) => {
                                    return (
                                        <TableRow key={i} className={classes.denseRow}>
                                            <TableCell component="th" scope="row" className={classes.denseRow}>{(row._attributes.id) ? row._attributes.id : i + 1}</TableCell>
                                            <TableCell className={classes.denseRow}>{(row._attributes.code) ? row._attributes.code : ((row._attributes.name) ? row._attributes.name : "")}</TableCell>
                                            <TableCell className={classes.denseRow}>{(row._attributes.descn?.length > 0) ? cropText(row._attributes.descn) :
                                                ((row._attributes.text?.length > 0) ? cropText(row._attributes.text) : "")}</TableCell>
                                            <TableCell className={classes.denseRow}>{statusSwitch(row._attributes.status)}</TableCell>
                                        </TableRow>
                                    );
                                }
                                )

                            }
                        </TableBody>
                    </Table>
                    
                </TableContainer>
            </>
        )
    };

}

export default withStyles(styles, { withTheme: true })(UploadInitSteps);