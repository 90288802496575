import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';


export const themeFR = createMuiTheme({
  palette: {
    primary: {
      main: '#112649',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9CD5F4',
      contrastText: '#6D6D6D',
    },
  },
  fontFamily: 'Roboto',
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: '#9CD5F4',
        '&:hover': {
          backgroundColor: '#112649',
          color: '#fff'
        },
        '&:disabled': {
          backgroundColor: '#D1D3D4',
          color: '6D6D6D'
        }
      }
    },
    MuiTable:{
    },
    MuiTableHead:{
      root: {
        backgroundColor: '#112649',
        color: 'white'
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: '#F0FFFF',
        },
      },
      head: {
        backgroundColor: '#112649',
        color: 'white'
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: '#112649',
        color: 'white'
      },
      stickyHeader: {
        backgroundColor: '#112649'
      }
    }
  },
  custom: {
    blockHeader: {
      backgroundColor: grey[300],
      width: 'inherit',
      margin: '0 0 .5em 0'
    }
  },
});
