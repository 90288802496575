/**
 * ToDO
 * Updated: 2020-09-22
 */

import React, { Component } from "react";
import { /* Checkbox,  */FormControlLabel, MuiThemeProvider, Switch } from "@material-ui/core";
import { themeFR } from '../assets/themes/frrmTheme';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import Link from '@material-ui/core/Link';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';

import signUp from '../service/user.service.signup';
import "./User.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#9CD5F4',
        '&:hover': {
            backgroundColor: '#112649',
            color: '#fff'
        },
        '&:disabled': {
            backgroundColor: '#D1D3D4',
            color: '6D6D6D'
        }
    },
});


class Registration extends Component {

    constructor(props) {
        super();
        this.state = {
            company: "",
            email: "",
            password: "",
            passwordConfirm: "",
            firstName: "",
            lastName: "",
            phone: "",
            message: "",
            confirmTermOfUse: true,
            showMessage: false,
            showMessageSuccess: false,
            loading: false,
            signup: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.callbackSignUp = this.callbackSignUp.bind(this);
    }

    componentDidMount() {       
        if (this.props.onPageChanged) {
            this.props.onPageChanged();
        }
    }

    /**
     * 
     */
    validateForm() {
        return this.state.company.length > 0
            && this.state.email.length > 0
            && this.state.password.length > 0
            && this.state.passwordConfirm.length > 0
            && this.state.firstName.length > 0
            && this.state.lastName.length > 0
            && this.state.confirmTermOfUse
            ;
    }

    /**
     * 
     * @param {*} event 
     */
    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true, showMessage: false, showMessageSuccess: false },
            () => {
                signUp(this.state.company, this.state.email, this.state.password, 
                    this.state.firstName, this.state.lastName, this.state.phone,
                    this.callbackSignUp, this.props.appBaseURL);
            });
    }

    /**
     * 
     * @param {*} e 
     */
    handleChange(e) {
        this.setState({
            [e.target.id]: e.target.value,
            showMessage: false
        });
    }

    /**
     * 
     * @param {*} event 
     */
    handleChangeCheckBox = (event) => {
        this.setState( { [event.target.id]: event.target.checked,
            showMessage: false } );
    };

    /**
     * 
     * @param {*} res 
     */
    callbackSignUp(res) {

        console.log('callbackSignUp: ', res);

        let attributes = {};
        if (attributes && res["XMLResponse"] && res["XMLResponse"]["_attributes"] ) {
            attributes = res["XMLResponse"]["_attributes"];
        }

        if (attributes && attributes.signup === 'true') {
            let msg = 'Confirmation email sent to you. Please confirm. You can close registration page.';
            if (attributes && attributes.message && attributes.message.length > 0) {
                msg = attributes.message;
            }

            this.setState({ showMessage: false, showMessageSuccess: true, message: msg, loading: false, signup: true });
            // auto-redirect with delay? e.g. 10 sec 
            // this.props.history.push('/');

        } else {

            var err = 'Something went wrong, please try again later';
            if (attributes && attributes.error && attributes.error.length > 0) {
                err = attributes.error;
            }

            this.setState({
                loading: false,
                signup: false,
                showMessage: true,
                showMessageSuccess: false,
                message: err
            });
        }
    }

    /**
     * 
     */
    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={themeFR}>
                <Container component="main" maxWidth="xs" >
                    <CssBaseline />
                    <div className={classes.paper}>

                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                <div style={{ display: this.state.showMessageSuccess ? "block" : "none" }}>
                                    <Alert severity="success">{this.state.message}</Alert>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ display: !this.state.showMessageSuccess ? "block" : "none" }}>
                            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="company"
                                            label="Company"
                                            name="company"
                                            autoComplete="company"
                                            autoFocus
                                            value={this.state.company}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="fname"
                                            name="firstName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="lname"
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="passwordConfirm"
                                            label="Confirm Password"
                                            type="password"
                                            id="passwordConfirm"
                                            autoComplete="new-password"
                                            value={this.state.passwordConfirm}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="phone"
                                            label="Phone"
                                            type="phone"
                                            id="phone"
                                            autoComplete='phone'
                                            value={this.state.phone}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Switch id="confirmTermOfUse" onChange={this.handleChangeCheckBox} checked={this.state.confirmTermOfUse} color="secondary" />}
                                            label="By clicking Sign Up, I Agree [Terms of Use] and [Privacy Policy]."
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    /* variant="contained" */

                                    /*  className={classes.submit} */
                                    disabled={!this.validateForm()}
                                >
                                    {"Sign In"}
                                </Button>
                               
                                <Grid container>
                                    <Grid item>
                                        <div style={{ display: this.state.showMessage ? "block" : "none" }}> <Alert severity="warning">{this.state.message}</Alert>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        {this.state.loading && <CircularProgress color="secondary" />}
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </Container>
            </MuiThemeProvider>
        )
    };


}

export default withRouter(withStyles(styles, { withTheme: true })(Registration));