import React, { Component } from "react";
import { Box } from "@material-ui/core";

export default class BoxKpi extends Component {

    render() {
        return (
            <Box
                boxShadow={2}
                style={{
                    padding: '0.5em',
                    margin: '0.2em',
                    backgroundColor: '#F2F4F3',
                    borderRadius: "0.5em",
                    borderColor: "black !important"
                }}
            >
                {this.props.children}
            </Box>
        );
    }
}
