/**
 * Updated: 2020-12-22
 */

import requestPost from './api.service';

export default function getKPIdata(data, callback, appBaseURL) {
    
    var convert = require('xml-js');

    function callbackKPIdata(response) {
        var respJson = {};
        if (response && response.length > 0) {
            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }

        if (callback) {
            callback(respJson);
        }
    }

    // ***
    let CMD = 'KPIUtil.DashboardKPI'; 
    //let blank = '';
    let xml = '<XMLRequest cmd="' + CMD + '" ';
    xml += 'type="' + data.type + '" ';
    xml += 'requestUser="' + data.user + '" ';
    xml += 'ytdMonth="' + data.ytdMonth + '" ';
    xml += ' >';
    xml += '</XMLRequest>';

    requestPost('', '', xml, callbackKPIdata, appBaseURL);
}