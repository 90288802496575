/** 
 * Origin-Destination airports lists
 * Updated: 2020-10-22
 */

import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BlockHeader from "../ui/BlockHeader";
import requestPost from '../../service/api.service';

class ODRange extends Component {

    constructor(props) {
        super();
        this.state = {
            OriginsData: [],
            DestinationsData: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.initAirportsCallback = this.initAirportsCallback.bind(this);
    }

    componentDidMount() {
        this.loadLists();
    }

    loadLists() {
        console.log('loadLists: ', this.props.appCmdGetPickList, ' ', 'OriginAirports');
        requestPost(this.props.appCmdGetPickList, '', 'OriginAirports', this.initAirportsCallback, this.props.appBaseURL);
    }

    initAirportsCallback(response) {
        console.log("getDataCallback:", response);
        if (!response) response = '';
        let listOD = this.preparePickList(response);
        this.setState({
            OriginsData: [...listOD],
            DestinationsData: [...listOD]
        })

    }

    preparePickList(data) {
        var parts = data.split("\n");

        var tList = [];
        var max = parts.length;
        for (var k = 0; k < max; k++) {
            var ind = parts[k].indexOf("\r")
            if (ind > 0) parts[k] = parts[k].substring(0, ind);
            var text = parts[k].split("|");
            if (text.length === 2 && text[0].length > 0 && text[1].length > 0) {
                var obj = {};
                obj.id = text[0];
                obj.text = text[1];
                tList[tList.length] = obj;
                //console.log ( obj.id + " => " + obj.text );
            }
        }
        return tList;
    }

    handleChange(event, value) {
        let oval = value ? value.id : null;
        let oname = "";
        if (value) {
            oname = event.target.id.split('-')[0];
        } else {
            let ci = event.target.parentElement.closest("div.MuiInput-root");
            if (ci) {
                let inp = ci.getElementsByTagName('input')[0];
                oname = inp.getAttribute("name");
            }
        }

        const val = {}
        val[oname] = oval;
        this.props.onValuesChanged(val);
    }


    render() {
        return (
            <div>
                <Grid container item spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <BlockHeader headertext={"Flight Parameters"}></BlockHeader>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="origin"
                            autoComplete={true}
                            autoSelect={true}
                            options={this.state.OriginsData}
                            getOptionLabel={(option) => option.text}
                            size="small"                            
                            renderInput={(params) => <TextField {...params} name="origin" error={!!this.props.errorMessage.origin}
                                label="Origin" margin="none" />}
                            onChange={this.handleChange}
                            style={{margin: 0}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="destination"
                            autoComplete={true}
                            autoSelect={true}
                            options={this.state.OriginsData}
                            getOptionLabel={(option) => option.text}
                            size="small"
                            renderInput={(params) => <TextField {...params} name="destination" error={!!this.props.errorMessage.destination}
                                label="Destination" margin="none" />}
                            onChange={this.handleChange}
                            style={{margin: 0}}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };

}

export default ODRange;