import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ConfirmationDialog extends Component {

    constructor(props) {
        super();
        this.state = {
            open: props.openConfirmation ? props.openConfirmation : false,
            type: props.confirmationType ? props.confirmationType : "",
        };
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    /** */
    handleClose = (e) => {
        console.log('ConfirmationDialog onClose', e);
        var val  = { openConfirmation: false, confirmationOk: false, confirmationType: this.state.confirmationType };
        this.handleConfirmation(val);
    };

    /** */
    handleOk = () => {
        var val  = { openConfirmation: false, confirmationOk: true, confirmationType: this.state.confirmationType };
        this.handleConfirmation(val);
    };

    /** */
    handleCancel = () => {
        var val  = { openConfirmation: false, confirmationOk: false, confirmationType: this.state.confirmationType };
        this.handleConfirmation(val);
    };

    /** */
    handleConfirmation(val) {
        if (this.props.onConfirmationChanged) {
            this.props.onConfirmationChanged(val);
        }
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{this.props.confirmationTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">{this.props.confirmationText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>Cancel</Button>
                        <Button onClick={this.handleOk} color="primary">Ok</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ConfirmationDialog);