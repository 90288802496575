/**
 * Navigation menu drawer
 * 
 * Updated: 2020-09-17
 */

import React, { Component } from 'react';
import clsx from 'clsx';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";

import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

import InfoIcon from "@material-ui/icons/Info";
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PublishIcon from '@material-ui/icons/Publish';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';

import grey from '@material-ui/core/colors/grey';

//import logo from '../../assets/images/FRM_logo.png';


const styles = theme => ({
    drawerPaper: { width: 'inherit', top: '80px', backgroundColor: '#F2F5F6' },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    linkDisable: {
        textDecoration: 'none',
        color: grey[300]
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
});


class NavDrawer extends Component {
    constructor(props) {
        super();

        this.state = {
            openUpload: false
        };

        this.handleUploadClick = this.handleUploadClick.bind(this);
    }


    /*     componentDidMount() {
            this.setState({
                drawerPaper2: {  
                    width: this.props.drawerPaper ? this.props.drawerPaper.width : 'inherit',
                    top: !this.props.appbarh || this.props.appbarh==null ? (this.props.drawerPaper ? this.props.drawerPaper.top : '100px') : this.props.appbarh+'px',
                }
            })
        } 
    */

    /** */
    handleUploadClick() {
        this.setState({ openUpload: !this.state.openUpload });
    }

    render() {
        const { classes } = this.props;
        //console.log('classes.drawerPaper: ', classes.drawerPaper);
        // classes.drawerPaper.top = this.props.appbarh + 'px';

        return (
            <Drawer
                style={{ width: '220px', top: this.props.appbarh }}
                variant="persistent"
                anchor="left"
                open={this.props.open}
                classes={{ paper: classes.drawerPaper }}
            >
                <List>
                    <Link to="/" className={classes.link} >
                        <ListItem button>
                            <ListItemIcon>
                                <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Login"} />
                        </ListItem>
                    </Link>
                    <Link to="/about" className={classes.link}>
                        <ListItem button>
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={"About"} />
                        </ListItem>
                    </Link>
                    <ListItem button onClick={this.handleUploadClick}>
                        <ListItemIcon>
                            <PublishIcon />
                        </ListItemIcon>
                        <ListItemText primary="Upload" />
                        {this.state.openUpload ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.openUpload} timeout="auto" className={classes.nested} unmountOnExit>
                        <List component="div" >
                            <Link to={this.props.islogin ? (this.props.ucode && !(this.props.ucode === 'demo' || this.props.ucode === 'mt') ? "/upload" : "#") : "/"}
                                className={clsx(this.props.islogin ? (this.props.ucode && !(this.props.ucode === 'demo' || this.props.ucode === 'mt') ? classes.link : classes.linkDisable) : classes.linkDisable,
                                    classes.nested)}
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <ImportExportOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Upload Data"} />
                                </ListItem>
                            </Link>
                            <Link to={this.props.islogin ? (this.props.ucode && !(this.props.ucode === 'demo' || this.props.ucode === 'mt') ? "/uploadhistory" : "#") : "/"}
                                className={clsx(this.props.islogin ? (this.props.ucode && !(this.props.ucode === 'demo' || this.props.ucode === 'mt') ? classes.link : classes.linkDisable) : classes.linkDisable,
                                    classes.nested)}
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <LibraryBooksOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Upload History"} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                    <ListItem button onClick={this.handleUploadClick}>
                        <ListItemIcon>
                            <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Booking" />
                    </ListItem>
                    <Collapse in={this.props.islogin} timeout="auto" className={classes.nested} unmountOnExit>
                        <List component="div" >
                            <Link to={this.props.islogin ? "/ebooking" : "/"} className={classes.link}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <ListIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Booking"} />
                                </ListItem>
                            </Link>
                            <Link to={this.props.islogin ? "/bookinghistory" : "/"} className={classes.link}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <ChangeHistoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Booking History"} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                    <Link to={this.props.islogin ? "/kpiRevenue" : "/"} className={classes.link}>
                        <ListItem button>
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Kpi Revenue"} />
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
        );
    }

}

export default withRouter(withStyles(styles, { withTheme: true })(NavDrawer));