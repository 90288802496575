/**
 * ToDO
 * Updated: 2020-09-17
 */

import React, { Component } from "react";

export default class Restoreextends extends Component {

    constructor(props) {
        super();
        this.state = {};
    }

    componentDidMount() {
        if (this.props.onPageChanged) {
            this.props.onPageChanged();
        }
    }

    render() {
        return (
            <div>Restore</div>
        );
    };
}