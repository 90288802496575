/**
 * Suggested itinenararies cards overview
 * Updated: 2020-10-27
 */

import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { /* TableContainer, */ Table, TableHead, TableBody, TableRow, TableCell, Snackbar } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import grey from "@material-ui/core/colors/grey";
import lightGreen from "@material-ui/core/colors/lightGreen";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';

import BookingOrderDetails from './booking.order.details';
//import viewdetails from '../assets/images/viewdetails.svg';

import { setConfirmed } from '../service/booking.service';


const styles = theme => ({
    card: {
        overflow: 'visible',
        background: 'none',
        display: 'flex',
        minWidth: 343,
        minHeight: 150
    },
    header: {
        backgroundColor: grey["100"]
    },
    content: {
        paddingTop: 0,
        textAlign: 'left',
        overflowX: 'auto',
        '& table': {
            marginBottom: 0,
        }
    }
});


class ItinerariesOverview extends Component {

    constructor(props) {
        super();

        this.state = {
            itineraries: props.itineraries,
            itinerariesActive: props.itinerariesActive,
            showOrderDetails: false,
            activerow: {},
            openConfirmedSuccess: false,
            openConfirmedError: false
        }

        this.handleConfirmedClick = this.handleConfirmedClick.bind(this);
        this.callbackConfirmed = this.callbackConfirmed.bind(this);
        this.onDetails = this.onDetails.bind(this);
        this.onValuesSubChanged = this.onValuesSubChanged.bind(this);
        this.handleCloseConfirmed = this.handleCloseConfirmed.bind(this);
        this.handleCloseConfirmedError = this.handleCloseConfirmedError.bind(this);
    }


    onValuesSubChanged(value) {
        this.setState(value);
    }


    handleConfirmedClick(itinerary) {
        // add additional attributes
        Object.keys(this.props).forEach(key => {
            if (key.startsWith("intuition_")) {
                itinerary[key] = this.props[key]
            }
        });

        if (this.props.customerPrice) {
            itinerary["customerPrice"] = this.props.customerPrice;
        }
        if (this.props.companyPrice) {
            itinerary["companyPrice"] = this.props.companyPrice;
        }

        setConfirmed(itinerary, this.callbackConfirmed, this.props.appBaseURL, this.props.ucode);
    }

    callbackConfirmed = (data) => {
        // console.log('callbackConfirmed: ', data);
        var resp = data?.XMLResponse?._attributes ? data.XMLResponse._attributes : {};
        if (resp?.dealIssuedId && (resp.dealIssuedId * 1) > 0) {
            this.setState({ openConfirmedSuccess: true, openConfirmedError: false });
        } else {
            this.setState({ openConfirmedSuccess: false, openConfirmedError: true });
        }
    }

    /** */
    handleCloseConfirmed(e) {
        this.setState({ openConfirmedSuccess: false, openConfirmedError: false },
            () => { this.props.history.push('/bookinghistory') });
        ;
    }

    /** */
    handleCloseConfirmedError(e) {
        this.setState({ openConfirmedSuccess: false, openConfirmedError: false });
    }

    /** */
    getDealsAcceptXmlCmd() {

    }

    /** */
    onDetails(row) {
        row['pcs'] = this.props.totalPieces;
        row['weight'] = this.props.totalWeight;
        row['cbm'] = this.props.totalCbm;
        row['goods'] = this.props.goods;
        row['code'] = this.props.conditions;

        this.setState({
            showOrderDetails: true,
            activerow: row
        });
    }



    render() {
        const { classes } = this.props;

        const getDeparture = (itinerary) => {
            return itinerary?.Leg?.length > 0 ? itinerary.Leg[0]._attributes.plannedDeparture : itinerary._attributes.leg1Departure;
        };

        const getArrival = (itinerary) => {
            return itinerary?.Leg?.length > 0 ? itinerary.Leg[0]._attributes.plannedArrival
                : (itinerary._attributes.leg2Arrival && itinerary._attributes.leg2Arrival.length > 0 ? itinerary._attributes.leg2Arrival
                    : itinerary._attributes.leg1Arrival);
        };

        const numberFormatPrecision = (value, precision) => {
            if (precision === undefined) precision = 2;
            try {
                return parseFloat(value).toFixed(precision)
            }
            catch (e) {
            }
            return value;
        };


        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    {
                        (this.props.loadingFinished && (!this.props.itineraries || this.props.itineraries.length === 0)) && (
                            <Grid item xs={12}>
                                <Alert severity="warning">No itineraries for these options</Alert>
                            </Grid>
                        )
                    }

                    {

                        <Snackbar open={this.state.openConfirmedSuccess} autoHideDuration={6000} onClose={this.handleCloseConfirmed}>
                            <Alert onClose={this.handleCloseConfirmed} severity="success">
                                {"Reservation successfully created. You will redirect to the histories list in a few seconds."}
                            </Alert>
                        </Snackbar>

                    }

                    {
                        <Snackbar open={this.state.openConfirmedError} autoHideDuration={6000} onClose={this.handleCloseConfirmedError}>
                            <Alert onClose={this.handleCloseConfirmedError} severity="error">
                                {"There were errors in reservation processing. You need to inform IT's staff."}
                            </Alert>
                        </Snackbar>
                    }
                    {this.state.showOrderDetails ?
                        <BookingOrderDetails key="BookingOrderDetails" onValuesChanged={this.onValuesSubChanged}
                            {...this.state.activerow} {...this.state.activerow.value}
                            showOrderDetails={this.state.showOrderDetails} /> : <></>
                    }

                    {
                        this.props.loading ?
                            (
                                <>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} sm={3}>
                                            <Skeleton variant="rect" width="90%" height={120} />
                                        </Grid>
                                        <Grid item xs={12} sm={8} style={{ padding: 0 }}>
                                            <Typography variant="h1" style={{ lineHeight: 1 }}>
                                                <Skeleton />
                                            </Typography>
                                            <Typography variant="h4">
                                                <Skeleton height={10} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={1} style={{ padding: "5px" }}>
                                            <Typography variant="h3" style={{ paddingTop: "5px" }}>
                                                <Skeleton animation="wave" width="50%" />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>

                            ) :
                            (

                                this.props.itineraries.map((itinerary, idx) => (
                                    <Grid item key={idx}>
                                        <Card className={classes.card}>
                                            <CardHeader className={classes.header}
                                                title={itinerary._attributes.origin + '-' + itinerary._attributes.destination}
                                                subheader={getDeparture(itinerary) + ' -- ' + getArrival(itinerary)}
                                            />
                                            <CardActionArea>
                                                <CardContent className={classes.content}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>origin</TableCell>
                                                                <TableCell>destination</TableCell>
                                                                <TableCell>flight</TableCell>
                                                                <TableCell>Departure</TableCell>
                                                                <TableCell>Arrival</TableCell>
                                                                <TableCell>Transfer (h)</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                (itinerary.Leg) ?
                                                                    (
                                                                        Array.isArray(itinerary.Leg) ? itinerary.Leg : [].concat(itinerary.Leg)).map((row, i) => {
                                                                            return (
                                                                                <TableRow key={i}>
                                                                                    <TableCell component="th" scope="row">{row["_attributes"].origin}</TableCell>
                                                                                    <TableCell>{row["_attributes"].destination}</TableCell>
                                                                                    <TableCell>{row["_attributes"].flight}</TableCell>
                                                                                    <TableCell>{row["_attributes"].plannedDeparture}</TableCell>
                                                                                    <TableCell>{row["_attributes"].plannedArrival}</TableCell>
                                                                                    <TableCell>{i === 0 && itinerary["_attributes"].diffHours > 0 ? itinerary["_attributes"].diffHours : ''}</TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }
                                                                        ) :
                                                                    (<></>)
                                                            }



                                                        </TableBody>
                                                    </Table>

                                                    {/* price-fare
                                                    <Typography gutterBottom variant="h5" component="h2">{'$' + itinerary._attributes.estimation}</Typography>
                                                    */}

                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.theader} rowSpan={2}></TableCell>
                                                                <TableCell className={classes.theader} colSpan={2} align="center">Customer Offer</TableCell>
                                                                <TableCell className={classes.theader} colSpan={2} align="center">Suggested</TableCell>
                                                                <TableCell className={classes.theader} colSpan={2} align="center">Company Offer</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell className={classes.theader} align="center">Per Kg</TableCell>
                                                                <TableCell className={classes.theader} align="center">Fare </TableCell>
                                                                <TableCell className={classes.theader} align="center">Per Kg</TableCell>
                                                                <TableCell className={classes.theader} align="center">Fare </TableCell>
                                                                <TableCell className={classes.theader} align="center">Per Kg</TableCell>
                                                                <TableCell className={classes.theader} align="center">Fare </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                <TableRow key={idx}>
                                                                    <TableCell align="center">
                                                                        {itinerary.isEditMode ? (
                                                                            <>
                                                                                <IconButton
                                                                                    aria-label="done"
                                                                                    onClick={() => this.onDone(idx)}
                                                                                >
                                                                                    <DoneAllOutlinedIcon fontSize="small" style={{ color: lightGreen[400] }} />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    aria-label="revert"
                                                                                    onClick={() => this.onRevert(idx)}
                                                                                >
                                                                                    <CancelOutlinedIcon fontSize="small" color="primary" />
                                                                                </IconButton>
                                                                            </>
                                                                        ) :
                                                                            (
                                                                                <IconButton
                                                                                    aria-label="edit"
                                                                                    onClick={() => this.onToggleEditMode(idx)}
                                                                                    disabled
                                                                                >
                                                                                    <EditOutlinedIcon fontSize="small" color="primary" />
                                                                                </IconButton>
                                                                            )
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">{this.props.customerPrice ? this.props.customerPrice * 1 : 0}</TableCell>
                                                                    <TableCell align="center">{this.props.customerPrice ? (this.props.customerPrice * 1) * (this.props.totalWeight * 1) : 0}</TableCell>
                                                                    <TableCell align="center">{numberFormatPrecision(itinerary._attributes.suggestedRate * 1, 2)}</TableCell>
                                                                    <TableCell align="center">{numberFormatPrecision((itinerary._attributes.suggestedRate * 1) * (this.props.totalWeight * 1), 0)}</TableCell>
                                                                    <TableCell align="center">{this.props.companyPrice ? this.props.companyPrice * 1 : 0}</TableCell>
                                                                    <TableCell align="center">{this.props.companyPrice ? (this.props.companyPrice * 1) * (this.props.totalWeight * 1) : 0}</TableCell>
                                                                </TableRow>
                                                            }
                                                        </TableBody>

                                                    </Table>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <Button size="small" onClick={() => this.onDetails(itinerary._attributes)} >Details</Button>
                                                <Button size="small" onClick={() => this.handleConfirmedClick(itinerary._attributes)} key={idx} data-type={'confirmed'} data-uuid={itinerary._attributes.uuid} data-uuid_booking_action={itinerary._attributes.uuid_booking_action}>Confirmed</Button>
                                            </CardActions>
                                        </Card>

                                    </Grid>
                                ))
                            )
                    }
                </Grid>
            </React.Fragment>
        )
    };

}

export default withRouter(withStyles(styles, { withTheme: true })(ItinerariesOverview));