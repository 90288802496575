
import { Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";

import { PieChart, Pie, Cell, Label } from "recharts";

import BoxKpi from '../ui/ui.box.kpi';

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    labelTop: {
        transform: 'translateY(-10px)'
    },
    labelBottom: {
        transform: 'translateY(+3px)'
    },
});


class ChartsValueMargin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            width: props.width,
            height: props.height
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    handleChange(event) {
    }

    render() {
        const { classes } = this.props;

        /*       const SecondValueLabel = ({ viewBox, value1, value2 }) => {
                   const { cx, cy } = viewBox;
                   return (
                       <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label"
                           textAnchor="middle" dominantBaseline="central">
                           <tspan alignmentBaseline="middle" fontSize="26">{value1}</tspan>
                           <tspan fontSize="14">{value2}</tspan>
                       </text>
                   )
               } 
               */

        return (
            <BoxKpi>                
                <Grid container item xs={12}>
                    <Grid item xs={5}>
                        <Typography variant="subtitle1" gutterBottom>{this.props.data.headerText}</Typography>
                        <Typography variant="h4" gutterBottom>{this.props.data.mainValue}</Typography>
                        {/*<Typography variant="body2" gutterBottom>{this.props.data.secondHeaderText}</Typography>*/}
                    </Grid>
                    <Grid item xs={7}>
                        <PieChart
                            viewBox="0 0 {this.state.width} {this.state.height}"
                            preserveAspectRatio="xMidYMid meet"
                            width={this.state.width}
                            height={this.state.height}
                        >
                            <Pie
                                data={this.props.data.data}
                                cx={this.state.width / 2}
                                cy={this.state.height / 2}
                                startAngle={180}
                                endAngle={0}
                                innerRadius={50}
                                outerRadius={75}
                                dataKey={this.props.data.dataValueKey}
                            >
                                {
                                    this.props.data.data.map((entry, index) => <Cell key={index} fill={entry.color} />)
                                }
                                <Label value={this.props.data.secondValueText} position="centerBottom" className={classes.labelTop} fontSize={'27px'} />
                                <Label value={this.props.data.secondHeaderText} position="centerTop" className={classes.labelBottom} />
                            </Pie>

                        </PieChart>
                    </Grid>
                </Grid>
            </BoxKpi>
        );
    }

}

export default withStyles(styles, { withTheme: true })(ChartsValueMargin);