/** 
 * main post with callback
 * Updated: 2020-09-15
 */
import axios from 'axios';

export default function requestPost(cmd, cls, datatxt, callback, appBaseURL) {

  const encodeForm = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }


  if (!cmd) {
    cmd = '';
  }
  if (!cls) {
    cls = '';
  }
  if (!datatxt) {
    datatxt = '';
  }

  let formData = { command: cmd, class: cls, data: datatxt };

  var postResponse = '';

  console.log('requestPost: ', appBaseURL, '\n', JSON.stringify(formData));
  axios.post(appBaseURL, encodeForm(formData), {
    headers:
    {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    withCredentials: true
  })
    .then((response) => {
      postResponse = response.data;
      // console.log("api.service response: " + response.data);
      if (callback) {
        callback(postResponse);
      }
    }, (error) => {
      console.log("api.service error: " + error);
      callback(error);
    });

}