/** 
 * Billet class
 * 
 * Updated: 2020-10-06
 */


import React, { Component } from "react";

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";

import BlockHeader from "../../components/ui/BlockHeader";

const styles = (theme) => ({
    denseRow: {
        height: '0.8em',
        padding: '1px'
    }
});


class UploadResult extends Component {

    constructor(props) {
        super();
        this.state = {
            loadedInfoList: props.loadedInfoList
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        return (
            <>
                <BlockHeader headertext={"Uploads Result"}></BlockHeader>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Count</TableCell>
                                <TableCell>From</TableCell>
                                <TableCell>To</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (Array.isArray(this.props.loadedInfoList) ? this.props.loadedInfoList : [].concat(this.props.loadedInfoList)).map((row, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">{row["_attributes"].groupName}</TableCell>
                                            <TableCell>{row["_attributes"].count}</TableCell>
                                            <TableCell>{row["_attributes"].dateFrom}</TableCell>
                                            <TableCell>{row["_attributes"].dateTo}</TableCell>
                                        </TableRow>
                                    );
                                }
                                )

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

}

export default withStyles(styles, { withTheme: true })(UploadResult);