
import React, { Component } from "react";
import BlockHeader from "../ui/BlockHeader";

import { Button, Fab, FormControl, FormControlLabel, /* FormControlLabel, */ FormLabel, Grid, Icon, /* MuiThemeProvider, */ Radio, RadioGroup } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

//import SvgIcon from '@material-ui/core/SvgIcon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PublishIcon from '@material-ui/icons/Publish';

import ExcelLogo from "../../assets/images/ExcelLogo.png";
/* import { themeFR } from "../../assets/themes/frrmTheme"; */
import { withStyles } from "@material-ui/core/styles";
import { Form } from "react-bootstrap";
import axios from 'axios';

/** 
 * Upload
 * 
 * Updated: 2020-09-28
 */
const styles = theme => ({
    steptext: {
        "font-weight": "bold",
    },
    linkStyleUp: {
        textDecoration: 'none',
        color: '#ffffff',
        '&:hover': {
            cursor: 'pointer',
            color: '#ffffff'
        }
    },
    linkStyleUpload: {
        textDecoration: 'none',
        color: '#1C75BC',
        '&:hover': {
            cursor: 'pointer',
            color: '#112649'
        },
        '&:disabled': {
            cursor: 'default',
            color: '#D1D3D4'
        }
    },
    icon: {
        marginLeft: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
});

class UploadStepsStart extends Component {

    constructor(props) {
        super();
        this.state = {
            upload_type: "append",
            file01: "",
            button_type: 1,
            original_url: "",
            submitted_file_name: "",
            user_name: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUpType = this.handleChangeUpType.bind(this);
        this.handleChangeUpload = this.handleChangeUpload.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onSubmitButton = this.onSubmitButton.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line no-undef
        var ucode = this.props.ucode;
        console.log("ucode: ", ucode);

        this.setState({
            user_name: ucode,
            original_url: window.location.href
        });
    }

    handleChange(e) {
        console.log(e.target.id, ": ", e.target.value);

    }

    handleChangeUpType(e) {
        //console.log(e.target.id, ": ", e.target.value);
        this.setState({
            upload_type: e.target.value,
            button_type: 1
        });
    }

    handleChangeUpload(e) {
        console.log("ChangeUpload: ", e.target.id, ": ", e.target.value, " :: ", e.target.files[0]);

        this.setState({
            submitted_file_name: e.target.files[0].name
        },
            () => {
                const val = {
                    error: false,
                    showMessageSuccess: false
                };
                this.props.onValuesChanged(val);
            });
    }

    handleClick(e) {
    }

    onSubmitButton(e) {

        e.preventDefault();

        const val = {
            error: false,
            errorMessage: ""
        };
        this.props.onValuesChanged(val);


        document.getElementById('submitted_file_name').value = this.state.submitted_file_name;
        document.getElementById('user_name').value = this.state.user_name;
        document.getElementById('original_url').value = this.state.original_url;

        var appUploadURL = this.props.appUploadURL;

        var uploaderForm = document.getElementById('uploader');
        const formData = new FormData(uploaderForm);

        var postResponse = '';

        axios({
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
            data: formData,
            url: appUploadURL,
            // onUploadProgress: (ev: ProgressEvent) => {
            //     const progress = ev.loaded / ev.total * 100;
            //     updateUploadProgress(Math.round(progress));
            // },
        })
            .then((response) => {
                postResponse = response.data;
                console.log("upload response: ", postResponse);
                const val = { loading: false, response: postResponse };

                this.setState({ submitted_file_name: "" },
                    () => {
                        this.props.onValuesChanged(val);
                        if (this.props.callbackUpload) {
                            this.props.callbackUpload(postResponse);
                        }
                    }
                );
            })
            .catch((err) => {
                console.error("upload file err", err);
                const val = {
                    loading: false, error: true,
                    errorMessage: err
                };
                this.props.onValuesChanged(val);
                if (this.props.callbackUpload) {
                    this.props.callbackUpload(err);
                }
            });


    }

    callbackSuggestRoutes = (data) => {
    }

    render() {
        const { classes } = this.props;
        return (

            <div>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <BlockHeader headertext={"Steps to Start"}></BlockHeader>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel className={classes.steptext} >
                                <Typography variant="h6">
                                    {"1. Download empty "}
                                    <Button onClick={this.handleClick} size="small"
                                        style={{ 'margin': 1, 'display': 'inline-block' }}>
                                        <a href="data.xls" target="_blank" className={classes.linkStyleUp}>
                                            <span style={{ paddingRight: '0.5em' }}>Excel file </span>
                                            <span style={{ verticalAlign: 'text-top' }}>
                                                <Icon>
                                                    <img alt="Excel" style={{ maxHeight: '1.2em', verticalAlign: 'text-bottom' }} src={ExcelLogo} />
                                                </Icon>
                                            </span>
                                        </a>
                                    </Button>
                                </Typography>
                            </FormLabel>

                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel className={classes.steptext} >
                                <Typography variant="h6">{"2. Fill Excel file with data. See example for "}
                                    <Button size="small"
                                        style={{ 'margin': 1, 'display': 'inline-block' }}> 
                                        <a href="demoUploadData_v2.xls" target="_blank" className={classes.linkStyleUp}>
                                            <span style={{ paddingRight: '0.5em' }}>data file</span>
                                        </a>
                                    </Button>
                                </Typography>
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Form id="uploader" name="uploader" >
                            <FormControl >
                                <FormLabel htmlFor="file01" className={classes.steptext} style={{ display: 'flex', flexDirection: 'row' }} >
                                    <Typography variant="h6" style={{ paddingRight: 5 }} >{"3. Upload file "}</Typography>
                                    <input id="file01" name="file01" type="file" size="40" style={{ display: "none" }} onChange={this.handleChangeUpload} />
                                    <Fab color="secondary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        variant="extended">
                                        <AddCircleOutlineIcon style={{ paddingRight: 5 }} /> Select File...
                                    </Fab>
                                </FormLabel>
                                <FormControl component="fieldset">
                                    <RadioGroup id="upload_type" name="upload_type" value={this.state.upload_type} onChange={this.handleChangeUpType}>
                                        <FormControlLabel value="append" control={<Radio />} label="Append uploaded data to existing one" />
                                        <FormControlLabel value="clear" control={<Radio />} label="Clear existing data before upload" />
                                    </RadioGroup>
                                </FormControl>
                                <input type="hidden" name="button_type" id="button_type" value="1" />
                                <input type="hidden" name="user_name" id="user_name" value="" />
                                <input type="hidden" name="original_url" id="original_url" value="" />
                                <input type="hidden" name="submitted_file_name" id="submitted_file_name" value="" />
                                <Button type="submit" name="submit01" id="submit01"
                                    disabled={this.state.submitted_file_name.length === 0}
                                    onClick={this.onSubmitButton} style={{ margin: 2 }}>
                                    Send  <PublishIcon /> {this.state.submitted_file_name}
                                </Button>
                            </FormControl>
                        </Form>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </div>

        );
    }

}

export default withStyles(styles, { withTheme: true })(UploadStepsStart);