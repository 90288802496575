/** 
 * 
 * Bars
 * 
 * Updated: 2020-12-26
 */

import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, BarChart } from 'recharts';
import BoxKpi from '../ui/ui.box.kpi';

class ChartsBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            width: props.width,
            height: props.height
        };
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
    }

    handleChange(event) {
    }

    render() {
        return (
            <BoxKpi>
                <Typography variant="subtitle1" gutterBottom>{this.props.data.headerText}</Typography>
                <BarChart
                    viewBox="0 0 {this.state.width} {this.state.height}"
                    preserveAspectRatio="xMidYMid meet"
                    width={this.state.width}
                    height={this.state.height}
                    data={this.props.data.data}
                    margin={{
                        top: 20, right: 10, bottom: 20, left: 10,
                    }}
                >
                    <CartesianGrid fill="#fff" stroke="#f5f5f5" />
                    <XAxis dataKey={this.props.dataKey} height={60} tickCount={10} interval={0} angle={-90} textAnchor="end" />
                    <YAxis tickFormatter={tick => {
                        return Math.round(tick / 1000).toString() + "K";
                    }} />
                    { /*<Bar dataKey="plan" barSize={20} fill="#8884d8" />*/}
                    <Bar dataKey={this.props.dataValueKey} barSize={20} fill="#880000" >
                        {
                            this.props.data.data.map((entry, index) => (
                                <Cell key={index} fill={index < 5 ? "#008800" : "#990000"} />
                            ))
                        }
                    </Bar>
                    <Tooltip cursor={{ fill: 'transparent' }} />
                </BarChart>
            </BoxKpi>
        );
    }

}


export default ChartsBar;