/** 
 * Updated: 2020-09-15
 */
import React, { Component } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { themeFR } from '../assets/themes/frrmTheme';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import Link from '@material-ui/core/Link';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';

import login from '../service/user.service.login';
import "./User.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#9CD5F4',
    '&:hover': {
      backgroundColor: '#112649',
      color: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#D1D3D4',
      color: '#6D6D6D'
    }
  },
  linkStyle: {
    textDecoration: 'none',
    color: '#1C75BC',
    '&:hover': {
      cursor: 'pointer',
      color: '#112649'
    },
    '&:disabled': {
      cursor: 'default',
      color: '#D1D3D4'
    }
  },
  disabledStyle: {
    cursor: 'default',
    color: '#D1D3D4'

  },
});


class Login extends Component {

  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      message: "",
      showMessage: false,
      loading: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.callbackUser = this.callbackUser.bind(this);
    this.handleClickLink = this.handleClickLink.bind(this);
  }

  componentDidMount() {
    this.clearUser();
    if (this.props.onPageChanged) {
      this.props.onPageChanged();
    }
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true },
      () => { login(this.state.email, this.state.password, this.callbackUser, this.props.appBaseURL); });
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
      showMessage: false
    });
  }

  handleClickLink(e) {
    this.props.history.push('/registration');
  }

  clearUser() {
    let resp = {
      login: false,
      username: "",
      ucode: ""
    };
    if (this.props.onLoginChanged) {
      this.props.onLoginChanged(resp);
    }
  }

  callbackUser(res) {
    let resp = {
      login: false,
      username: "",
      ucode: ""
    };

    if (res.getAttributeValue("login") === 'true') {

      this.setState({ showMessage: false, message: "", loading: false });
      resp.login = true;
      resp.username = res.getAttributeValue("user");
      resp.ucode = this.state.email;

      if (this.props.onLoginChanged) {
        this.props.onLoginChanged(resp);
      }
      //this.props.history.push('/ebooking'); 
      this.props.history.push('/kpiRevenue');

    } else {
      this.setState({
        loading: false,
        showMessage: true,
        message: "There are no credentials with these parameters"
      });

    }
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={themeFR}>
        <Container component="main" maxWidth="xs" >
          <CssBaseline />
          <div className={classes.paper}>

            <Typography component="h1" variant="h5">
              Sign in
        </Typography>
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.handleChange}
              />

              <Button
                type="submit"
                fullWidth
                /* variant="contained" */

                /*  className={classes.submit} */
                disabled={!this.validateForm()}
              >
                {"Sign In"}
              </Button>
              <Grid container spacing={2}>
                <Grid item>
                  
                  <Typography disabled={true} className={classes.disabledStyle} variant="body2">
                    {"Forgot password?"}
                  </Typography>
                  
                </Grid>
                <Grid item>
                  <Typography className={classes.linkStyle} onClick={this.handleClickLink} variant="body2" >
                    {"Don't have an account? Sign Up"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <div style={{ display: this.state.showMessage ? "block" : "none" }}> <Alert severity="warning">{this.state.message}</Alert>
                  </div>
                </Grid>
                <Grid item>
                  {this.state.loading && <CircularProgress color="secondary" />}
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </MuiThemeProvider>
    )
  };


}

export default withRouter(withStyles(styles, { withTheme: true })(Login));