/** 
 * Updated: 2020-10-12
 */

import React, { Component } from "react";
import { Box, Button, Grid, Input, Modal, TextField, Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { withStyles } from "@material-ui/core/styles";

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import blueGrey from "@material-ui/core/colors/blueGrey";
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';

import BlockHeader from "../components/ui/BlockHeader";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    }
});


class IntuitionModal extends Component {

    constructor(props) {
        super();
        this.state = {
            "intuition_Show_Up": props["intuition_Show_Up"] /* ? props["intuition_Show_Up"] : 30 */,
            "intuition_Low_Show": props["intuition_Low_Show"] /* ? props["intuition_Low_Show"] : 10 */,
            "intuition_High_Show": props["intuition_High_Show"] /* ? props["intuition_High_Show"] : 7 */,
            "intuition_Change_Client": props["intuition_Change_Client"] /* ? props["intuition_Change_Client"] : 6 */,
            "intuition_Change_Carrier": props["intuition_Change_Carrier"] /* ? props["intuition_Change_Carrier"] : 5 */,
            "intuition_Other_Show": props["intuition_Other_Show"] /* ? props["intuition_Other_Show"] : 10 */,
            "intuition_No_Show": props["intuition_No_Show"] /* ? props["intuition_No_Show"] : 12 */,
            "intuition_Cancel": props["intuition_Cancel"] /* ? props["intuition_Cancel"] : 8 */,
            "intuition_Security": props["intuition_Security"] /* ? props["intuition_Security"] : 10 */,
            "intuition_Under_Tender": props["intuition_Under_Tender"] /* ? props["intuition_Under_Tender"] : 5 */,
            "intuition_OtherNoShow": props["intuition_OtherNoShow"] /* ? props["intuition_OtherNoShow"] : 7 */,
            "intuition_reliability": props["intuition_reliability"] /* ? props["intuition_reliability"] : 5 */,
            "intuition_completeness": props["intuition_completeness"] /* ? props["intuition_completeness"] : 6 */,
            "intuition_deadlines": props["intuition_deadlines"] /* ? props["intuition_deadlines"] : 4 */,
            "intuition_quality": props["intuition_quality"] /* ? props["intuition_quality"] : 7 */,
            "intuition_important_opinion": props["intuition_important_opinion"] /* ? props["intuition_important_opinion"] : "" */,
        };

        this.handleSubChanged = this.handleSubChanged.bind(this);
        this.handleIntuitionClose = this.handleIntuitionClose.bind(this);
        this.handleIntuitionCancel = this.handleIntuitionCancel.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSliderCommitted = this.handleChangeSliderCommitted.bind(this);
        this.handleChangeSlider = this.handleChangeSlider.bind(this);

        this.onCancel = this.onCancel.bind(this);
        this.onOk = this.onOk.bind(this);

    }

    handleSubChanged = (values) => {
        this.setState({ ...values }, () => {
            if (this.props.onValuesChanged) {
                // console.log("intuition handleSubChanged: ", values);
                this.props.onValuesChanged(values);
            }
        });
    };

    /** */
    handleIntuitionClose(e) {
        // this.setState({ openIntuition: false });
        var values = { openIntuition: false };
        values = { ...values, ...this.state.value, ...this.state };
        this.handleSubChanged(values);
    }

    /** */
    handleIntuitionCancel(e) {
        // this.setState({ openIntuition: false });
        var values = { openIntuition: false, skipClearSuggest: true };
        this.handleSubChanged(values);
    }

    /** */
    valuetextPercent(value) {
        return `${value}%`;
    }

    /** */
    handleChange = (event) => {
        let val = {}
        val[event.target.id] = event.target.value;
        this.setState(val);
    };

    /** */
    handleChangeSliderCommitted = (event, value) => {
        this.setState({ [event.originalTarget.parentElement.id]: value });
        // console.log('ChangeCommitted e: e.originalTarget.parentElement.id: ', event.originalTarget.parentElement.id, ' val:', value)
    };

    handleChangeSlider = (name) => (e, value) => {
        this.setState({ [name]: value });
    }

    /** */
    sliderContainer(name, min, max, step, labelText) {
        return (
            <>
                <Grid item xs={12} sm={3}>
                    <Typography id={name + "_Lbl"} gutterBottom variant="body2">{labelText}</Typography>
                </Grid>
                <Grid item xs={12} sm={9} container direction="row">
                    <Grid item xs={10} >

                        <Slider
                            id={name}
                            name={name}
                            onChangeCommitted={this.handleChangeCommitted}
                            onChange={this.handleChangeSlider(name)}
                            value={this.state[name]}
                            aria-labelledby={name + "_Lbl"}
                            valueLabelDisplay="auto"
                            step={step}
                            marks
                            min={min}
                            max={max}
                        />
                    </Grid>
                    <Grid item xs={2} >
                        {this.sliderValueContainer(name)}
                    </Grid>
                </Grid>
            </>
        );
    }

    /** */
    sliderValueContainer(valuename) {
        return (
            <Box borderRadius="50%" borderColor="text.primary">
                <Typography gutterBottom variant="body2" component="div"
                    style={{
                        border: "1px solid blue", borderRadius: "50%", width: "2em",
                        height: "2em", marginLeft: ".5em", display: "inline-block", verticalAlign: "middle", textAlign: "center",
                        lineHeight: "1.8em"
                    }}>{this.state[valuename]}</Typography>
            </Box>
        );

    }

    /** */
    onCancel = (e) => {
        this.handleIntuitionCancel(e);
    };

    /** */
    onOk = (e) => {
        this.handleIntuitionClose(e);
    };


    /** */
    render() {
        return (
            <>
                <Modal aria-labelledby="intuition-title"
                    aria-describedby="intuition-description"
                    disableBackdropClick={true}
                    open={this.props.openIntuition}
                    onClose={this.handleIntuitionClose}
                >
                    <div style={{ position: 'absolute', top: '10%', left: '30%', right: '10%', padding: '1em', backgroundColor: blueGrey[50] }} >
                        <BlockHeader headertext={"Intuition"}></BlockHeader>
                        <div id="intuition-description" >
                            <Grid container direction="row" spacing={2}>
                                <Grid item container xs={12} sm={6}>

                                    <Box
                                        component={Grid}
                                        container
                                        boxShadow={3}
                                        spacing={1}
                                        style={{ padding: 1, margin: '0.8em 0 0.2em 0', backgroundColor: '#fff' }}
                                    >
                                        {this.sliderContainer("intuition_Show_Up", 0, 100, 5, "Show Up")}
                                        {this.sliderContainer("intuition_Low_Show", 0, 100, 5, "Low Show")}
                                        {this.sliderContainer("intuition_High_Show", 0, 100, 5, "High Show")}
                                        {this.sliderContainer("intuition_Change_Client", 0, 100, 5, "Change By Client")}
                                        {this.sliderContainer("intuition_Change_Carrier", 0, 100, 5, "Change By Carrier")}
                                        {this.sliderContainer("intuition_Other_Show", 0, 100, 5, "Other Show")}
                                    </Box>
                                </Grid>

                                <Grid item container xs={12} sm={6}>
                                    <Box
                                        component={Grid}
                                        container
                                        boxShadow={3}
                                        spacing={1}
                                        style={{ padding: 1, margin: '0.8em 0 0.2em 0', backgroundColor: '#fff' }}
                                    >
                                        {this.sliderContainer("intuition_No_Show", 0, 100, 5, "No Show")}
                                        {this.sliderContainer("intuition_Cancel", 0, 100, 5, "Cancel")} 
                                        {this.sliderContainer("intuition_Security", 0, 100, 5, "Security")}
                                        {this.sliderContainer("intuition_Under_Tender", 0, 100, 5, "Under Tender")}
                                        {this.sliderContainer("intuition_OtherNoShow", 0, 100, 5, "Other No Show")}                                       
                                    </Box>
                                </Grid>

                                <Grid item container xs={12} sm={6}>
                                    <Box
                                        component={Grid}
                                        container
                                        boxShadow={3}
                                        spacing={1}
                                        style={{ padding: 1, margin: '0.8em 0 0.2em 0', backgroundColor: '#fff' }}
                                    >
                                        {this.sliderContainer("intuition_reliability", 0, 10, 1, "Agent Reliability")}
                                        {this.sliderContainer("intuition_completeness", 0, 10, 1, "Agent Completeness")}
                                        {this.sliderContainer("intuition_deadlines", 0, 10, 1, "Agent Deadlines")}
                                        {this.sliderContainer("intuition_quality", 0, 10, 1, "Agent Quality")}
                                    </Box>
                                </Grid>

                                <Grid item container xs={12} sm={6}>
                                    <Box
                                        component={Grid}
                                        container
                                        boxShadow={3}
                                        spacing={1}
                                        style={{ padding: 1, margin: '0.8em 0 0.2em 0', backgroundColor: '#fff' }}
                                    >
                                        <Grid item xs={12}>
                                            <TextField id="intuition_important_opinion" label="Important Opinion"
                                                value={this.state.intuition_important_opinion}
                                                onChange={this.handleChange}
                                                multiline rows={3} style={{ width: '100%' }} />
                                        </Grid>
                                    </Box>
                                </Grid>

                            </Grid>

                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12}>
                                    <Button size="small" onClick={() => this.onCancel()} ><CancelIcon style={{ color: red[500] }} /> Cancel</Button>
                                    <Button size="small" onClick={() => this.onOk()} style={{ float: "right" }}><CheckCircleOutlineIcon style={{ color: lightGreen['A400'] }} /> OK</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Modal>
            </>
        );
    };

}

export default withStyles(styles, { withTheme: true })(IntuitionModal);
