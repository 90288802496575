/** 
 * 
 * Updated: 2020-12-26
 */


import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import BoxKpi from '../ui/ui.box.kpi';


class ChartsBarSynch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            width: props.width,
            height: props.height
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    handleChange(event) {
    }

    render() {
        return (
            <BoxKpi>
                <Typography variant="subtitle1" gutterBottom>{this.props.data.headerText}</Typography>
                <ComposedChart
                    viewBox="0 0 {this.state.width} {this.state.height}"
                    preserveAspectRatio="xMidYMid meet"
                    syncId="syncId"
                    width={this.state.width}
                    height={this.state.height}
                    data={this.props.data.data}
                    margin={{ top: 10, right: 20, bottom: 0, left: 5 }}
                    barGap={0}
                >
                    <CartesianGrid fill="#fff" stroke="#f5f5f5" />
                    <XAxis dataKey={this.props.data.dataKey}  />
                    <YAxis tickFormatter={tick => { return Math.round(tick / 1000).toString() + "k"; }} />
                    <Tooltip />
                    <Legend />
                    {
                        this.props.data.valuesKeys1.map(
                            (item, idx) => (<Bar key={idx} dataKey={item.name} fill={item.color}></Bar>)
                        )
                    }
                </ComposedChart>

                <Typography variant="subtitle1" gutterBottom>{this.props.data.secondHeaderText}</Typography>
                <ComposedChart
                    viewBox="0 0 {this.state.width} {this.state.height}"
                    preserveAspectRatio="xMidYMid meet"
                    syncId="syncId"
                    width={this.state.width}
                    height={this.state.height}
                    data={this.props.data.data}
                    margin={{ top: 10, right: 20, bottom: 0, left: 5 }}
                    barGap={0}
                >
                    <CartesianGrid fill="#fff" stroke="#f5f5f5" />
                    <XAxis dataKey={this.props.data.dataKey} />
                    <YAxis tickFormatter={tick => { return Math.round(tick / 1000).toString() + "k"; }} />
                    <Tooltip />      
                           
                    {
                        this.props.data.valuesKeys2.map(
                            (item, idx) => (<Bar key={idx} dataKey={item.name} fill={item.color}></Bar>)
                        )
                    }
                </ComposedChart>
            </BoxKpi>

        );
    }
}

export default ChartsBarSynch;