/**
 * Booking cargoes parameters list
 * Updated: 2020-10-26
 */

import React, { Component } from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';

import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';


import { withStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import lightGreen from "@material-ui/core/colors/lightGreen";
import deepOrange from "@material-ui/core/colors/deepOrange";

// import { FormControl, FormLabel, Input, InputBase } from "@material-ui/core";
import ConfirmationDialog from '../ui/ConfirmationDialog';


const styles = theme => ({
    theader: {
        "font-family": "OpenSansCondensed-Bold, sans-serif",
        fontSize: "0.8em",
        "line-height": "normal"
    },
    ttotals: {
        "font-family": "OpenSansCondensed-Bold, sans-serif",
        fontSize: "0.8em",
        "line-height": "normal",
        "font-weight": "500",
        color: grey[900],
        backgroundColor: grey[50]
    },
    vttotals: {
        /*  "font-size": "0.8em", */
        color: grey[900]
    },
    vetotals: {
        /*   "font-size": "0.8em", */
        color: deepOrange[500]
    },
    input: {

    },
    tableCell: {

    }

});

const EditableTableCell = ({ row, name, onChange, classes }) => {

    const { isEditMode } = row;
    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? (
                <Input
                    value={row[name]}
                    name={name}
                    onChange={e => onChange(e, row)}
                    className={classes.input}
                />
            ) : (
                    row[name]
                )}
        </TableCell>
    );
};

// function roundToN(num, precision) {
//     return +(Math.round(num + "e+" + precision) + "e-" + precision);
// }

class CargoesList extends Component {

    constructor(props) {
        super();
        this.state = {
            cargoesList: props.cargoesList,
            previous: {},
            openConfirmationDelete: false,
            rowToRemove: -1
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCargoes = this.handleChangeCargoes.bind(this);
        this.onToggleEditMode = this.onToggleEditMode.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onRevert = this.onRevert.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onConfirmationDelete = this.onConfirmationDelete.bind(this);
    }

    componentDidMount() {
        var _cargoesList = this.createRows();
        this.setState({ cargoesList: _cargoesList },
            () => { this.handleChangeCargoes() });
    }


    /** ? */
    handleChangeCargoes(e) {
        var val = { totalPieces: this.totalPieces(this.state.cargoesList), totalWeight: this.totalWeight(this.state.cargoesList), totalCbm: this.totalCbm(this.state.cargoesList) };
        if (this.props.onValuesChanged) {
            this.props.onValuesChanged(val);
        }
    }

    /** */
    handleChange(e) {
        var oname = e.target.id;
        var oval = e.target.value;
        this.setState({
            [e.target.id]: e.target.value
        });

        const val = {}
        val[oname] = oval;
    }

    // Table processing ///////////////////////////////

    /**  */
    onToggleEditMode = (id) => {
        var _cargoesList = this.state.cargoesList.map(row => {
            if (row.id === id) {
                return { ...row, isEditMode: !row.isEditMode };
            }
            return row;
        });

        this.setState({ cargoesList: _cargoesList });
    };

    /** */
    onDone = (id) => {
        var previous = this.state.previous;
        delete previous[id];
        this.setState({ previous: previous },
            () => {
                //console.log("onDone: id:", id, " previous: ", previous);
                this.onToggleEditMode(id);
                this.handleChangeCargoes();
            }
        );
    }

    /** */
    onRevert = (id) => {
        var newRows = this.state.cargoesList.map(row => {
            if (row.id === id) {
                return this.state.previous[id] ? this.state.previous[id] : row;
            }
            return row;
        });
        this.setState({ cargoesList: newRows },
            () => {
                var previous = this.state.previous;
                delete previous[id];
                this.setState({ previous: previous },
                    () => {
                        //console.log("onRevert: id:", id, " previous: ", previous);
                        this.onToggleEditMode(id)
                    }
                );

            });
        this.handleChangeCargoes();
    };

    /** */
    onChange = (e, row) => {
        var value = e.target.value;
        var name = e.target.name;
        var id = row.id;

        //console.log("onChange: (s0) id:", row.id, " previous: ", this.state.previous);
        if (!this.state.previous[row.id]) {
            var previous = {};
            previous[row.id] = row;
            this.setState({ previous: previous },
                () => {
                    var newRows = this.state.cargoesList.map(row => {
                        if (row.id === id) {
                            row[name] = value;
                            var rowup = this.updateRowCaclulatedValues(row);
                            return { ...rowup, [name]: value };
                        }
                        return row;
                    });

                    this.setState({ cargoesList: newRows },
                        () => { }
                    );
                });
        }
        else {
            var newRows = this.state.cargoesList.map(row => {
                if (row.id === id) {
                    row[name] = value;
                    var rowup = this.updateRowCaclulatedValues(row);
                    return { ...rowup, [name]: value };
                }
                return row;
            });

            this.setState({ cargoesList: newRows });
        }

    };

    /** */
    onRemove = (i) => {

        this.setState({ openConfirmationDelete: true, rowToRemove: i },
            () => { console.log("Open confirmation delete !") });
    };

    /** */
    onConfirmationDelete(val) {
        this.setState({ openConfirmationDelete: val.openConfirmation },
            () => {
                if (val.confirmationOk) {
                    var newRows = this.state.cargoesList.filter((x, j) => j !== this.state.rowToRemove);
                    console.log("onRemove: id:", this.state.rowToRemove, " rows: ", newRows);
                    this.setState({ cargoesList: newRows, rowToRemove: -1 },
                        () => { this.handleChangeCargoes() });
                } else {
                    this.setState({ rowToRemove: -1 });
                }
            }
        );
    }

    /** */
    onAdd = () => {
        var id = 0;
        var rows = this.state.cargoesList;
        if (rows.length > 0) {
            var maxid = rows.reduce((max, p) => p.id > max ? p.id : max, rows[0].id);
            id = maxid + 1;
        }
        var row = this.createRow(id, 'Box', 0, 0, 0, 0, 0, 0, 0, 0);
        rows.push(row);
        this.setState({ cargoesList: rows },
            () => { this.handleChangeCargoes() });

        //this.handleChangeCargoes();
    }
    
    /**  */
    roundToN(num, precision) {
        return +(Math.round(num + "e+" + precision) + "e-" + precision);
    };

    // temp dev
    createRows() {
        var rows = [];
        let id = 0;
        rows.push(this.createRow(id++, 'Box', 2, 100, 50, 50, 150, .25, 300, 0.5));
        rows.push(this.createRow(id, 'Box', 1, 50, 40, 70, 100, .14, 100, 0.1));
        return rows;
    };

    updateRowCaclulatedValues(row) {
        // var pcsVolume = this.rowPcsVolume(row.pcsLength, row.pcsWidth, row.pcsHeight);
        // var subTotKg = this.rowSubTotalWeight(row.pieces, row.pcsWeight);
        // var subTotCbm = this.rowSubTotalCbm(row.pieces, row.pcsVolume);
        row.pcsVolume = this.rowPcsVolume(row.pcsLength, row.pcsWidth, row.pcsHeight);
        row.subTotKg = this.rowSubTotalWeight(row.pieces, row.pcsWeight);
        row.subTotCbm = this.rowSubTotalCbm(row.pieces, row.pcsVolume);
        return row;
    }

    rowPcsVolume(pcsLength, pcsWidth, pcsHeight) {
        return this.roundToN((pcsLength * pcsWidth * pcsHeight) / 1000000, 2);
    };

    rowSubTotalWeight(pieces, pcsWeight) {
        return this.roundToN(pieces * pcsWeight, 0);
    };

    rowSubTotalCbm(pieces, pcsVolume) {
        return this.roundToN(pieces * pcsVolume, 1);
    };

    /** DEV */
    createRow(id, type, pieces, pcsLength, pcsWidth, pcsHeight, pcsWeight) {
        var pcsVolume = this.rowPcsVolume(pcsLength, pcsWidth, pcsHeight);
        var subTotKg = this.rowSubTotalWeight(pieces, pcsWeight);
        var subTotCbm = this.rowSubTotalCbm(pieces, pcsVolume);
        return { id, type, pieces, pcsLength, pcsWidth, pcsHeight, pcsWeight, pcsVolume, subTotKg, subTotCbm, isEditMode: false, canDeleted: true };
    };
    /** DEV */

    ccyFormat(num) {
        return `${num.toFixed(2)}`;
    };

    totalPieces(items) {
        return items.map(({ pieces }) => pieces).reduce((sum, i) => sum + Number(i), 0);
    };

    totalWeight(items) {
        return items.map(({ subTotKg }) => subTotKg).reduce((sum, i) => sum + i, 0);
    };

    totalCbm(items) {
        return items.map(({ subTotCbm }) => subTotCbm).reduce((sum, i) => sum + i, 0);
    };
    // Table processing ///////////////////////////////


    /** */
    render() {
        const { classes } = this.props;
        return (
            <TableContainer component={Paper}>
                <Table aria-label="cargoes list" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.theader} rowSpan={2}>
                                <IconButton
                                    aria-label="add"
                                    onClick={() => this.onAdd()}
                                >
                                    <AddCircleOutlineIcon fontSize="large" style={{ color: lightGreen[400] }} />
                                </IconButton>
                            </TableCell>
                            <TableCell className={classes.theader} rowSpan={2}>Type</TableCell>
                            <TableCell className={classes.theader} rowSpan={2}>Pieces</TableCell>
                            <TableCell className={classes.theader} rowSpan={2}>Length (cm)</TableCell>
                            <TableCell className={classes.theader} rowSpan={2}>Width (cm)</TableCell>
                            <TableCell className={classes.theader} rowSpan={2}>Height (cm)</TableCell>
                            <TableCell className={classes.theader} rowSpan={2}>Volume (cbm) </TableCell>
                            <TableCell className={classes.theader} rowSpan={2}>Weight (kg)</TableCell>
                            <TableCell className={classes.theader} colSpan={2} align="center">SubTotal</TableCell>
                            <TableCell className={classes.theader} rowSpan={2}></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.theader}>Kg</TableCell>
                            <TableCell className={classes.theader}>CBM </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.cargoesList.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        {row.isEditMode ? (
                                            <>
                                                <IconButton
                                                    aria-label="done"
                                                    onClick={() => this.onDone(row.id)}
                                                >
                                                    <DoneAllOutlinedIcon fontSize="small" style={{ color: lightGreen[400] }} />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="revert"
                                                    onClick={() => this.onRevert(row.id)}
                                                >
                                                    <CancelOutlinedIcon fontSize="small" color="primary" />
                                                </IconButton>
                                            </>
                                        ) :
                                            (
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => this.onToggleEditMode(row.id)}
                                                >
                                                    <EditOutlinedIcon fontSize="small" color="primary" />
                                                </IconButton>
                                            )
                                        }
                                    </TableCell>
                                    <TableCell align="left">{row.type}</TableCell>
                                    <EditableTableCell align="right" {...{ row, name: "pieces", onChange: this.onChange, classes }} >{row.pieces}</EditableTableCell>
                                    <EditableTableCell align="right" {...{ row, name: "pcsLength", onChange: this.onChange, classes }} >{row.pcsLength}</EditableTableCell>
                                    <EditableTableCell align="right" {...{ row, name: "pcsWidth", onChange: this.onChange, classes }} >{row.pcsWidth}</EditableTableCell>
                                    <EditableTableCell align="right" {...{ row, name: "pcsHeight", onChange: this.onChange, classes }} >{row.pcsHeight}</EditableTableCell>
                                    <TableCell align="right">{this.ccyFormat(row.pcsVolume)}</TableCell>
                                    <EditableTableCell align="right" {...{ row, name: "pcsWeight", onChange: this.onChange, classes }} >{row.pcsWeight}</EditableTableCell>
                                    <TableCell align="right" className={!!(row.subTotKg === 0) ? classes.vetotals : classes.vttotals}>{row.subTotKg}</TableCell>
                                    <TableCell align="right" className={!!(row.subTotCbm === 0) ? classes.vetotals : classes.vttotals}>{this.ccyFormat(row.subTotCbm)}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="remove"
                                            onClick={() => this.onRemove(row.id)}>
                                            <DeleteForeverOutlinedIcon style={{ color: deepOrange[500] }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}

                        <TableRow>
                            <TableCell rowSpan={3} colSpan={1} />
                            <TableCell className={classes.ttotals} colSpan={2} align="right">Pieces :</TableCell>
                            <TableCell className={classes.ttotals}>
                                <div id="totalPieces" className={!!(this.totalPieces(this.state.cargoesList) === 0) ? classes.vetotals : classes.vttotals} >{this.totalPieces(this.state.cargoesList)}</div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.ttotals} colSpan={2} align="right">Weight (kg) :</TableCell>
                            <TableCell className={classes.ttotals}>
                                <div id="totalWeight" className={!!(this.totalWeight(this.state.cargoesList) === 0) ? classes.vetotals : classes.vttotals} >{this.totalWeight(this.state.cargoesList)}</div>
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.ttotals} colSpan={2} align="right">Volume (cbm):</TableCell>
                            <TableCell className={classes.ttotals}>
                                <div id="totalCbm" className={!!(this.totalCbm(this.state.cargoesList) === 0) ? classes.vetotals : classes.vttotals} >{this.totalCbm(this.state.cargoesList)}</div>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
                {
                    this.state.openConfirmationDelete &&
                    (<ConfirmationDialog openConfirmation={this.state.openConfirmationDelete}
                        confirmationType={"rowdelete"}
                        confirmationTitle={"Delete row"}
                        confirmationText={"Do you really want to delete this row?"}
                        onConfirmationChanged={this.onConfirmationDelete} />
                    )
                }
            </TableContainer >
        );
    }

}

export default withStyles(styles, { withTheme: true })(CargoesList);