/**
 * Updated: 2020-09-15
 */
import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import BlockHeader from "../ui/BlockHeader";

class DatesRange extends Component {

    static defaultProps = (function () {
        const dtFrom = new Date();
        const dtTo = new Date();
        dtTo.setDate(dtFrom.getDate() + 1);

        return {
            formdata: { dateFrom: dtFrom, dateTo: dtTo }
        }

    })();

    constructor(props) {
        super();
        this.state = {
            dateFrom: props.dateFrom,
            dateTo: props.dateTo
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let oval = event.target.valueAsDate;
        let oname = event.target.id;
        let val = {}
        val[oname] = oval;
        /*  console.log('handleChange DatesRange: ' + JSON.stringify(val)); */
        this.props.onValuesChanged(val);
    }

    render() {
        return (
            <div>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <BlockHeader headertext={"Dates Parameters"}></BlockHeader>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="dateFrom"
                            label="From"
                            type="date"
                            size="small"    
                            defaultValue={this.state.dateFrom.toISOString().substring(0, 10)}
                            /* className={classes.textField} */
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="dateTo"
                            label="To"
                            type="date"
                            size="small"    
                            defaultValue={this.state.dateTo.toISOString().substring(0, 10)}
                            /* className={classes.textField} */
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleChange}
                        />
                    </Grid>
                </Grid>

            </div >
        );

    };
}

export default DatesRange;