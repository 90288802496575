/** 
 * Updated: 2020-10-12
 */

import React, { Component } from "react";
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
//import OutlinedDiv from '../ui/OutlinedDiv';
// import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
//import { themeFR } from "../../assets/themes/frrmTheme";
import BlockHeader from "../ui/BlockHeader";
import CargoesList from './parameters.dimensions';


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
    fsubHeader: {
        backgroundColor: '#84AEDC'
    },
});


class ShipmentParameters extends Component {

    conditionsList = [
        { id: 'C', text: 'Standard' },
        { id: 'EX', text: 'Dangerous goods with shipments on cargo aircrafts only' },
        { id: 'CA', text: 'Life supporting conditions for animals' },
        { id: 'M', text: 'Mail processing conditions' },
        { id: 'P', text: 'Need fast shipment because of perishable cargo' },
        { id: 'PH', text: 'Special conditions for vulnerable cargoes' },
        { id: 'DV', text: 'Special safety conditions for valuable cargoes' }
    ];

    constructor(props) {
        super();
        this.state = {
            goods: props.goods,
            totalPieces: props.totalPieces,
            totalWeight: props.totalWeight,
            totalCbm: props.totalCbm,
            ConditionsData: [],
            cargoesList: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
        this.handleSubChanged = this.handleSubChanged.bind(this);
    }

    componentDidMount() {
        this.setState({
            ConditionsData: [...this.conditionsList]
        })
    }

    handleChange(event) {
        let oval = event.target.value;
        let oname = event.target.id;
        let val = {}
        val[oname] = oval;
        this.props.onValuesChanged(val);
    }

    handleChangeAutocomplete(event, value) {
        let oval = value ? value.id : null;
        let oname = "";
        if (value) {
            oname = event.target.id.split('-')[0];
        } else {
            let ci = event.target.parentElement.closest("div.MuiInput-root");
            if (ci) {
                let inp = ci.getElementsByTagName('input')[0];
                oname = inp.getAttribute("name");
            }
        }

        const val = {}
        val[oname] = oval;
        this.props.onValuesChanged(val);
    }

    handleSubChanged = (values) => {
        this.setState({ ...values }, () => {
            if (this.props.onValuesChanged) {
                this.props.onValuesChanged(values);
            }
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>

                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <BlockHeader headertext={"Shipment Parameters"}></BlockHeader>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Autocomplete
                            id="conditions"
                            required={true}
                            options={this.state.ConditionsData}
                            getOptionLabel={(option) => option.text}
                            fullWidth
                            size="small"    
                            renderInput={(params) => <TextField {...params} name="conditions" required={true} error={!!this.props.errorMessage.conditions} label="Conditions:" margin="normal" />}
                            onChange={this.handleChangeAutocomplete}
                            style={{margin: 0}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            type="text"
                            required
                            fullWidth
                            autoComplete="goods"
                            id="goods"
                            label="Goods:"
                            name="goods"
                            value={this.state.goods}
                            onChange={this.handleChange}
                            error={!!this.props.errorMessage.goods}

                        />
                    </Grid>
                </Grid>

                <Grid container item style={{borderColor: "secondary.main"}} spacing={2} xs={12}>

                    <Grid item xs={12} sm={4} md={4}>
                        <FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                            <Input
                                id="totalPieces"
                                type="number"
                                value={this.state.totalPieces}
                                onChange={this.handleChange}
                                endAdornment={<InputAdornment position="end">Pcs</InputAdornment>}
                                aria-describedby="total-pieces-helper-text"
                                inputProps={{
                                    'aria-label': 'pieces',
                                }}
                                disabled={this.props.showCargoesList}
                                error={!!this.props.errorMessage.totalPieces}
                            />
                            <FormHelperText id="total-pieces-helper-text" error={!!this.props.errorMessage.totalPieces}
                            >{this.props.errorMessage.totalPieces ? this.props.errorMessage.totalPieces : 'Pieces'}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <FormControl size="small" className={clsx(classes.margin, classes.withoutLabel, classes.textField)} >
                            <Input
                                id="totalWeight"
                                value={this.state.totalWeight}
                                type="text"
                                onChange={this.handleChange}
                                endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                                aria-describedby="total-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                disabled={this.props.showCargoesList}
                                error={!!this.props.errorMessage.totalWeight}                                
                            />
                            <FormHelperText id="total-weight-helper-text" error={!!this.props.errorMessage.totalWeight}
                            >{this.props.errorMessage.totalWeight ? this.props.errorMessage.totalWeight : 'Weight'}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <FormControl size="small" className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                            <Input
                                id="totalCbm"
                                value={this.state.totalCbm}
                                onChange={this.handleChange}
                                endAdornment={<InputAdornment position="end">Cbm</InputAdornment>}
                                aria-describedby="total-cbm-helper-text"
                                inputProps={{
                                    'aria-label': 'cbm',
                                }}
                                disabled={this.props.showCargoesList}
                                error={!!this.props.errorMessage.totalCbm}
                            />
                            <FormHelperText id="total-cbm-helper-text" error={!!this.props.errorMessage.totalCbm}
                            >{this.props.errorMessage.totalCbm ? this.props.errorMessage.totalCbm : 'Volume'}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid container item style={{borderColor: "secondary.main"}} spacing={2} xs={12}>
                        {this.props.showCargoesList ?
                            (<CargoesList key="CargoesList" cargoesList={this.state.cargoesList} onValuesChanged={this.handleSubChanged} />) :
                            (<></>)
                        }

                    </Grid>



                </Grid>
            </div>
        );
    };
}

export default withStyles(styles, { withTheme: true })(ShipmentParameters);