/**
 * ToDO
 * Updated: 2020-10-27
 */

import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';

class About extends Component {

  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    if (this.props.onPageChanged) {
      this.props.onPageChanged();
    }
  }

  render() {
    return (
      <Container>
        <Typography variant="h3" gutterBottom>About</Typography>

        <div className={"sppb-addon-content"}><p>FreightRM offers air cargo businesses a&nbsp;full smart revenue management solution</p>
          <ul>
            <li>Dynamic rates</li>
            <li>Integration with input from human insights</li>
            <li>Support for accepting&nbsp;or rejecting order decisions</li>
            <li><strong><span style={{ fontWeight: 400 }}>A continually self-learning system b</span></strong>ased on&nbsp;Artificial Intelligence</li>
            <li>Playground / Sandbox</li>
            <li>Modular architecture</li>
            <li>Affordable, flexible and adaptive</li>
          </ul>
        </div>
      </Container>
    );
  }
}

export default About;