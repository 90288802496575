/** 
 * Updated: 2020-09-15
 */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from "../user/UserLogin";
import Registration from "../user/UserRegistration";
import Restore from "../user/UserRestore";
import About from '../components/About';
import BookingEClient from '../booking/booking.eclient';
import BookingHistory from '../booking/booking.history';
import UserUpload from '../user/UserUpload';
import UserUploadHistory from '../user/UsreUploadHistory';
import KpiRevenue from '../kpi/kpi.Revenue';

class Routes extends Component {

    constructor(props) {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <Switch >
                <Redirect exact from="/" to="/login" />
                <Route path="/login">
                    <Login onLoginChanged={this.props.onLoginChanged} onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL} />
                </Route>
                <Route path="/registration">
                    <Registration onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL} />
                </Route>
                <Route path="/restore">
                    <Restore onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL} />
                </Route>
                <Route path="/about">
                    <About onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL} />
                </Route>
                <Route path="/ebooking">
                    <BookingEClient onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL} appCmdGetPickList={this.props.appCmdGetPickList} 
                    ucode={this.props.ucode}/>
                </Route>
                <Route path="/bookinghistory">
                    <BookingHistory onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL} appCmdGetPickList={this.props.appCmdGetPickList} 
                    ucode={this.props.ucode} />
                </Route>
                <Route path="/upload" >
                    <UserUpload onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL} appUploadURL={this.props.appUploadURL} />
                </Route>
                <Route path="/uploadhistory" >
                    <UserUploadHistory onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL}
                        appUploadURL={this.props.appUploadURL} appDownloadURL={this.props.appDownloadURL} ucode={this.props.ucode} 
                        />
                </Route>
                <Route path="/kpiRevenue">
                    <KpiRevenue onPageChanged={this.props.onPageChanged} appBaseURL={this.props.appBaseURL} ucode={this.props.ucode} />
                </Route>

            </Switch>
        );
    }
}

export default Routes;