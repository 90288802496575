/** 
 * 
 * Updated: 2020-10-06
 */


import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress, Grid } from "@material-ui/core";
import convert from 'xml-js';

import UploadInitSteps from "../components/parameters/user.upload.initsteps";
import UploadStepsStart from "../components/parameters/user.upload.stepsstart";
import UploadResult from "../components/parameters/user.upload.uploadres";
import BlockHeader from "../components/ui/BlockHeader";

import requestPost from '../service/api.service';

const styles = (theme) => ({
    fbutton: {
        background: "#9CD5F4",
        color: "#ffffff",
        font: 'Roboto, sans-serif'
    }
});


class UserUpload extends Component {

    //const convert = require('xml-js');

    constructor(props) {
        super();
        this.state = {
            loading: false,
            response: "",
            error: false,
            errorMessage: "",
            showMessageSuccess: false,
            message: "",
            loguuid: "",
            stepsInfoList: [],
            loadedInfoList: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubChanged = this.handleSubChanged.bind(this);
        this.callbackUpload = this.callbackUpload.bind(this);
        this.getDataCallback = this.getDataCallback.bind(this);
        this.nextInitStepCallback = this.nextInitStepCallback.bind(this);
        this.historicDataCallback = this.historicDataCallback.bind(this);

    }

    componentDidMount() {
        if (this.props.onPageChanged) {
            this.props.onPageChanged();
        }

        console.log("UserUpload ucode:", this.props.ucode);
        this.getData();
    }

    /**  */
    handleSubChanged = (values) => {
        this.setState({ ...values }, () => {
        });
    };

    /** */
    handleChange(e) {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    /** */
    callbackUpload(response) {
        console.log("callbackUpload ", response);
        var respJson = {};
        if (response && response.length > 0) {

            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }

        var error = false;
        var code = 0;
        var errorMessage = "";
        var message = "File successfully uploaded. Starting to fill data.";
        var loguuid = "";

        if (/* respJson.XMLResponse["_attributes"].error &&  */ respJson?.XMLResponse?._attributes?.error?.length > 0) {
            errorMessage = respJson.XMLResponse["_attributes"].error;
        }

        if (respJson?.XMLResponse?._attributes?.code) {
            code = respJson.XMLResponse["_attributes"].code;
        }

        if (respJson?.XMLResponse?._attributes?.uuid) {
            loguuid = respJson.XMLResponse["_attributes"].uuid;
        }

        error = code !== 0 && errorMessage.length > 0;
        this.setState({
            error: error, errorMessage: errorMessage,
            showMessageSuccess: !error,
            message: message,
            loguuid: loguuid
        },
            () => {
                // if not error start to update date
                if (!error) {
                    this.nextInitStep();
                }

            });
    }


    /**  */
    getData() {

        var xml = '<XMLRequest cmd="TenantInitUtil.list" ';
        xml += ' />';

        console.log(xml);
        this.setState({ loading: true },
            () => { requestPost('', '', xml, this.getDataCallback, this.props.appBaseURL) });

    }

    /**  */
    getDataCallback(response) {
        console.log("getDataCallback:", response);
        var respJson = {};
        if (response && response.length > 0) {

            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }
        console.log("respJson: ", respJson);
        let stepsInfoList = [];
        if (respJson?.XMLResponse?.Data?.Row) {
            stepsInfoList = this.updateStepsInfo(respJson.XMLResponse.Data.Row);
        }
        let loadedInfoList = [];
        if (respJson?.XMLResponse?.Group?.Data) {
            loadedInfoList = this.updateLoadedInfo(respJson.XMLResponse.Group.Data);
        }

        this.setState({ stepsInfoList: stepsInfoList, loadedInfoList: loadedInfoList, loading: false },
            () => {
                var rowsFailure = this.state.stepsInfoList.filter((step, i) => step.status === "Failure");
                if (rowsFailure.length === 0) {
                    var rowsContinue = this.state.stepsInfoList.filter((step, i) => step.status === "Active" || step.status === "Pending");
                    if (rowsContinue.length > 0) {
                        this.nextInitStep();
                    } else {
                        var rowsNoStatus = this.state.stepsInfoList.filter((step, i) => !(step.status));
                        if( rowsNoStatus.length > 0) {
                            this.setState({ showMessageSuccess: false, error: false });
                        } else {
                            this.setState({ showMessageSuccess: true, message: "All was done with last upload", error: false });
                        }
                    }
                } else {
                    this.setState({ showMessageSuccess: false, message: "", error: true, errorMessage: "There was error in last update" });
                }
            });
    }

    /** */
    updateStepsInfo(nodeData) {
        var stepsInfoList = [];
        if (nodeData) {
            if (Array.isArray(nodeData)) {
                stepsInfoList = [...nodeData];
            } else {
                stepsInfoList.push(nodeData);
            }
        }
        return stepsInfoList;
    }

    /** */
    updateLoadedInfo(nodeGroup) {
        var loadedInfoList = [];
        if (nodeGroup) {
            if (Array.isArray(nodeGroup)) {
                loadedInfoList = [...nodeGroup];
            } else {
                loadedInfoList.push(nodeGroup);
            }
        }
        return loadedInfoList;
    }

    /** */
    nextInitStep() {
        var xml = '<XMLRequest cmd="TenantInitUtil.next"  />';
        console.log(xml);
        this.setState({ loading: true },
            () => {
                requestPost('', '', xml, this.nextInitStepCallback, this.props.appBaseURL)
            });
    }

    /** */
    nextInitStepCallback(response) {
        try {

            console.log("getDataCallback:", response);
            var respJson = {};
            if (response && response.length > 0) {

                respJson = convert.xml2js(response, { compact: true, spaces: 2 });
            }
            console.log("respJson: ", respJson);
            let stepsInfoList = !!respJson.XMLResponse.Data ? this.updateStepsInfo(respJson.XMLResponse.Data.Row) : []; // this.state.stepsInfoList;
            let loadedInfoList = !!respJson.XMLResponse.Group ? this.updateLoadedInfo(respJson.XMLResponse.Group.Data) : this.state.loadedInfoList;

            this.setState({ stepsInfoList: stepsInfoList, loadedInfoList: loadedInfoList, loading: false },
                () => {
                    var responseStatus = respJson.XMLResponse["_attributes"].status;
                    var serverErrorCode = respJson.XMLResponse["_attributes"].code;

                    if (!responseStatus) responseStatus = '';
                    if (!serverErrorCode) serverErrorCode = '0';

                    if (responseStatus === 'Success' || responseStatus === 'Warning') {
                        this.nextInitStep();
                    } else if (responseStatus === 'Finish' /* && serverErrorCode === '0' */) {
                        console.log('Finish nextInit:', responseStatus, serverErrorCode);
                        this.setState({
                            loading: false,
                            showMessageSuccess: true,
                            message: "Finish initial update steps",
                            error: false
                        },
                            () => {
                                this.getHistoricData();
                            });
                    } else {
                        console.log('nextInitStepCallback unprocessed:', responseStatus, serverErrorCode);
                        this.setState({
                            loading: false,
                            showMessageSuccess: false,
                            error: true,
                            errorMessage: "error processing " + responseStatus
                        });
                    }
                }
            );


        } catch (ex) {
            console.log(ex.message);
        }

    }

    /** */
    getHistoricData() {
        var xml = '<XMLRequest cmd="TenantInitUtil.getHistoricData" uuid="' + this.state.loguuid + '" />';
        this.setState({ loading: true },
            () => {
                requestPost('', '', xml, this.historicDataCallback, this.props.appBaseURL)
            });
    }

    /** */
    historicDataCallback(response) {
        console.log("historicDataCallback: ", response);
        var respJson = {};
        if (response?.length > 0) {

            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }
        let stepsInfoList = !!respJson?.XMLResponse?.Data ? this.updateStepsInfo(respJson.XMLResponse.Data.Row) : [];
        this.setState({ stepsInfoList: stepsInfoList, loguuid: "", loading: false },
            () => { });
    }

    /** */
    render() {
        return (
            <Container maxWidth={false}>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <BlockHeader headertext={"Upload Data"}></BlockHeader>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <UploadStepsStart appBaseURL={this.props.appBaseURL} appUploadURL={this.props.appUploadURL}
                            onValuesChanged={this.handleSubChanged} callbackUpload={this.callbackUpload} />
                        <Grid item container>
                            <Grid item xs={1}>
                                {this.state.loading && <CircularProgress color="secondary" />}
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: this.state.error ? "block" : "none" }}> <Alert severity="warning">{this.state.errorMessage}</Alert>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: this.state.showMessageSuccess ? "block" : "none" }}>
                                    <Alert severity="success">{this.state.message}</Alert>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={8}>
                        <Grid item xs={12}>
                            <UploadInitSteps appBaseURL={this.props.appBaseURL} appUploadURL={this.props.appUploadURL} stepsInfoList={this.state.stepsInfoList} />
                        </Grid>
                        <Grid item xs={12}>
                            <UploadResult appBaseURL={this.props.appBaseURL} appUploadURL={this.props.appUploadURL} loadedInfoList={this.state.loadedInfoList}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }

}

export default withStyles(styles, { withTheme: true })(UserUpload);