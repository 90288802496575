/**
 * processing user actions
 * Updated: 2020-09-22
 */

import requestPost from './api.service.xmlhttp';

var convert = require('xml-js');


/**
 * 
 * @param {*} company 
 * @param {*} email 
 * @param {*} password 
 * @param {*} passwordConfirm 
 * @param {*} firstName 
 * @param {*} lastName 
 * @param {*} phone 
 * @param {*} cbSignUp 
 * @param {*} appBaseURL 
 */
function signUp(company, email, password, firstName, lastName, phone, cbSignUp, appBaseURL) {

    function callbackSignUp(response) {
         console.log("callbackSignUp: ", response)
        // eslint-disable-next-line no-undef
        var respJson = {};
        if (response && response.length > 0) {
            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }
        if (cbSignUp) {
            cbSignUp(respJson);
        }
    }


    console.log('service signUp: ', company, email, firstName, lastName);

    if( !(phone && phone.length>0)) {
        phone='';
    }

    if (!(company && company.length > 0 && email && email.length > 0
        && password && password.length > 0 
        && firstName && firstName.length > 0 && lastName && lastName.length > 0)) {
            var resp = { "error": "Not all fields are filled in correctly." };
            console.log(resp.error, company, email, password, firstName, lastName, phone);
            if (cbSignUp) {
                cbSignUp(resp);
            }
            return;
    }

    let CMD = 'User.signup';
    //let category = 'EB';
    let xml = '<XMLRequest cmd="' + CMD + '" ';
    xml += 'company="' + company + '" ';
    xml += 'email="' + email + '" ';
    xml += 'password="' + password + '" ';
    xml += 'firstName="' + firstName + '" ';
    xml += 'lastName="' + lastName + '" ';
    xml += 'phone="' + phone + '" ';
    xml += ' />';

    console.log("signUp xml: ", xml);

    requestPost('', '', xml, callbackSignUp, appBaseURL);

}

export default signUp;