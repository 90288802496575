/** 
 * 
 * Updated: 2020-10-06
 */

import React, { Component } from "react";

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import Paper from '@material-ui/core/Paper';

import { withStyles } from "@material-ui/core/styles";


import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import grey from "@material-ui/core/colors/grey";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
//import deepOrange from "@material-ui/core/colors/deepOrange";
import lightBlue from "@material-ui/core/colors/lightBlue";
import red from "@material-ui/core/colors/red";
import indigo from "@material-ui/core/colors/indigo";

import BlockHeader from "../../components/ui/BlockHeader";


const styles = (theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
});

class UserUploadHistoryList extends Component {

    constructor(props) {
        super();
        this.state = {
            loading: false,
            response: "",
            error: false,
            errorMessage: "",
            showMessageSuccess: false,
            message: "",
            loguuid: "",
            historicUploadsList: props.historicUploadsList
        };

        this.handleRowClick = this.handleRowClick.bind(this);
    }

    /** */
    handleRowClick(e) {
        console.log("handleRowClick: ", e.target);
        var uuid = "";
        if (e.target?.parentNode?.dataset?.key) {
            //this.getDataDetails(e.target.parentNode.dataset.key);
            uuid = e.target.parentNode.dataset.key;
        }

        if (this.props.handleLogUuidChanged) {
            this.props.handleLogUuidChanged(uuid);
        }

        // TODO select row
    }

    /** */
    render() {

        const statusSwitch = (status) => {
            if (!status) status = '';
            switch (status.toString().toLowerCase()) {
                case "active": return <PlayCircleFilledWhiteOutlinedIcon style={{ color: lightBlue[500] }} />;
                case "failure": return <ErrorOutlineOutlinedIcon style={{ color: red[500] }} />;
                case "pending": return <HourglassEmptyOutlinedIcon style={{ color: indigo[500] }} />;
                case "success": return <CheckCircleOutlineOutlinedIcon style={{ color: lightGreen[500] }} />;
                case "warning": return <ReportProblemOutlinedIcon style={{ color: orange[400] }} />;
                default: return <HelpOutlineOutlinedIcon style={{ color: grey[500] }} />;
            }

        };

        const getAHref = (row) => {
            var href = this.props.appDownloadURL + "?uuid="+ row._attributes.uuid 
            + "&u=" + row._attributes.user + "&f=" + row._attributes.file; 
            return href;
        };


        return (
            <>
                <BlockHeader headertext={"History"}></BlockHeader>
                <TableContainer component={Paper} style={{ maxHeight: 580 }}>

                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Import Date</TableCell>
                                <TableCell>File</TableCell>
                                <TableCell>Clear</TableCell>
                                <TableCell>User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (Array.isArray(this.props.historicUploadsList) ? this.props.historicUploadsList : [].concat(this.props.historicUploadsList)).map((row, i) => {
                                    return (
                                        <TableRow key={row._attributes.uuid} data-key={row._attributes.uuid} onClick={this.handleRowClick}>
                                            <TableCell>{statusSwitch(row._attributes.status)}</TableCell>
                                            <TableCell>{row._attributes.date}</TableCell>
                                            <TableCell>
                                                <a href={getAHref(row)} target="_blank" title={row._attributes.file} >{row._attributes.file}</a>                                                
                                            </TableCell>
                                            <TableCell>{row._attributes.mode}</TableCell>
                                            <TableCell>{row._attributes.user}</TableCell>
                                        </TableRow>
                                    );
                                }
                                )
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </>

        );

    }



}

export default withStyles(styles, { withTheme: true })(UserUploadHistoryList);