/**
 * e-booking reservation version
 * Updated: 2020-10-27
 */

import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Icon, InputAdornment, TextField } from '@material-ui/core';

import { AccountCircle } from '@material-ui/icons';
import customerService from '../assets/images/customerService.svg';

import DatesRange from '../components/parameters/parameters.dates-range';
import ODRange from '../components/parameters/parameters.od';
import ShipmentParameters from '../components/parameters/parameters.shipment';
import ItinerariesOverview from './itineraries.overview';
import suggestCargoRoutes from '../service/booking.service';
import IntuitionModal from './booking.intuition.modal';


const styles = (theme) => ({
  fbutton: {
    background: "#9CD5F4",
    color: "#ffffff",
    font: 'Roboto, sans-serif'
  },
  margin: {
    margin: theme.spacing(1),
  },
});

class BookingEClient extends Component {

  constructor(props) {
    super();

    const dtFrom = new Date();
    const dtTo = new Date();
    dtTo.setDate(dtFrom.getDate() + 7);

    this.state = {
      origin: "",
      destination: "",
      dateFrom: dtFrom, dateTo: dtTo,
      saleDate: (new Date()),
      minHours: 3, maxHours: 36,
      itineraries: [],
      itinerariesActive: false,
      error: false,
      errorMessage: {},
      loading: false,
      loadingFinished: false,
      openIntuition: false,
      customerPrice: 0,
      companyPrice: 0,
      companyPriceConfirmed: 0,
      intuition_Cancel: 0,
      intuition_Change_Carrier: 0,
      intuition_Change_Client: 0,
      intuition_High_Show: 0,
      intuition_Low_Show: 0,
      intuition_No_Show: 0,
      intuition_OtherNoShow: 0,
      intuition_Other_Show: 0,
      intuition_Security: 0,
      intuition_Show_Up: 0,
      intuition_Under_Tender: 0,
      intuition_completeness: 0,
      intuition_deadlines: 0,
      intuition_important_opinion: "",
      intuition_quality: 0,
      intuition_reliability: 0,
    };

    this.hanldeSubChanged = this.handleSubChanged.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleIntuition = this.handleIntuition.bind(this);
    this.handleIntuitionClose = this.handleIntuitionClose.bind(this);
    this.getIntuition = this.getIntuition.bind(this);
    this.onClearSuggestRoutes = this.onClearSuggestRoutes.bind(this);
  };

  /** */
  componentDidMount() {
    this.handleNext();
    if (this.props.onPageChanged) {
      this.props.onPageChanged();
    }
  };

  /** */
  handleSubChanged = (values) => {
    // console.log("Booking Client: handleSubChanged : ", values);
    var skipCLear = !!values.skipClearSuggest;
    delete values["skipClearSuggest"];
    this.setState({ ...values, error: false, errorMessage: {} }, () => {
      // console.log("skipCLear: ", skipCLear)
      if(!skipCLear) {
        // console.log("start call ClearSuggestRoutes: ", skipCLear)
        this.onClearSuggestRoutes();
      }
      // console.log("start call handleNext. ")
      this.handleNext();
    });
  };

  /** */
  handleChange(event) {
    let oval = event.target.value;
    let oname = event.target.id;
    let val = {}
    val[oname] = oval;
    this.setState(val, () => { this.onClearSuggestRoutes() });
  }

  /** */
  handleNext = () => {

    let isError = false;
    let errors = {};

    if (!this.state.origin || this.state.origin == null || this.state.origin.length === 0) {
      isError = true;
      errors = { ...errors, origin: "enter origin" };
    }

    if (!this.state.destination || this.state.destination == null || this.state.destination.length === 0) {
      isError = true;
      errors = { ...errors, destination: "enter destination" };
    }

    if (!this.state.conditions || this.state.conditions == null || this.state.conditions.length === 0) {
      isError = true;
      errors = { ...errors, conditions: "enter conditions" };
    }

    if (!this.state.goods || this.state.goods == null || this.state.goods.length === 0) {
      isError = true;
      errors = { ...errors, goods: "enter goods" };
    }

    if (!this.state.totalPieces || this.state.totalPieces == null || this.state.totalPieces.length === 0) {
      isError = true;
      errors = { ...errors, totalPieces: "enter Pieces" };
    }

    if (!this.state.totalWeight || this.state.totalWeight == null || this.state.totalWeight.length === 0) {
      isError = true;
      errors = { ...errors, totalWeight: "enter Weight" };
    }

    if (!this.state.totalCbm || this.state.totalCbm == null || this.state.totalCbm.length === 0) {
      isError = true;
      errors = { ...errors, totalCbm: "enter Cbm" };
    }


    if (!isError) {

      this.setState(prevState => ({
        error: false,
        errorMessage: {}
      }));

    } else {
      this.setState(prevState => ({
        error: true,
        errorMessage: errors
      }));
    }

  };

  /** */
  handleRequestInfo = (event) => {
    this.handleNext();
  };


  /** */
  getSuggestRoutes = (event) => {
    if (this.state.error) {
      // show required error

    } else {
      this.setState({ itinerariesActive: true, loading: true, loadingFinished: false },
        () => { suggestCargoRoutes(this.state, this.callbackSuggestRoutes, this.props.appBaseURL); }
      );
    }
  };

  /** */
  onClearSuggestRoutes = () => {
    this.setState({ itineraries: [] });
  };

  /** */
  callbackSuggestRoutes = (data) => {
    let itinerariesres = [];

    if (data?.XMLResponse?.Itinerary) {
      if (Array.isArray(data.XMLResponse.Itinerary)) {
        itinerariesres = [...data.XMLResponse.Itinerary];
      } else {
        itinerariesres.push(data.XMLResponse.Itinerary);
      }
    }

    this.setState({ loading: false, loadingFinished: true, itineraries: itinerariesres });
  };


  /** */
  getIntuition = () => {

    return {
      intuition_Cancel: this.state.intuition_Cancel,
      intuition_Change_Carrier: this.state.intuition_Change_Carrier,
      intuition_Change_Client: this.state.intuition_Change_Client,
      intuition_High_Show: this.state.intuition_High_Show,
      intuition_Low_Show: this.state.intuition_Low_Show,
      intuition_No_Show: this.state.intuition_No_Show,
      intuition_OtherNoShow: this.state.intuition_OtherNoShow,
      intuition_Other_Show: this.state.intuition_Other_Show,
      intuition_Security: this.state.intuition_Security,
      intuition_Show_Up: this.state.intuition_Show_Up,
      intuition_Under_Tender: this.state.intuition_Under_Tender,
      intuition_completeness: this.state.intuition_completeness,
      intuition_deadlines: this.state.intuition_deadlines,
      intuition_important_opinion: this.state.intuition_important_opinion,
      intuition_quality: this.state.intuition_quality,
      intuition_reliability: this.state.intuition_reliability
    };
  }

  /** */
  handleIntuition(e) {
    this.setState({ openIntuition: true });
  }

  /** */
  handleIntuitionClose(e) {
    this.setState({ openIntuition: false });
  }

  /** */
  render() {

    const { classes } = this.props;

    return (
      <Container maxWidth={false}>
        <div>
          <h2>Booking</h2>
        </div>
        <Grid container spacing={2}>


          <Grid item container xs={12} sm={2}>
            <ODRange key="ODRange" appCmdGetPickList={this.props.appCmdGetPickList} appBaseURL={this.props.appBaseURL}
              {...this.state} {...this.state.value} onValuesChanged={this.handleSubChanged} />
          </Grid>

          <Grid item container xs={12} sm={2}>
            <DatesRange key="DatesRange" onValuesChanged={this.handleSubChanged} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} />
          </Grid>

          <Grid item container xs={12} sm={8}>
            <ShipmentParameters key="ShipmentParameters" onValuesChanged={this.handleSubChanged} errorMessage={this.state.errorMessage}
              showCargoesList={true} />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12} sm={2}>
              <TextField
                size="small"
                className={classes.margin}
                id="customerPrice"
                label="Customer Price"
                InputProps={{
                  startAdornment: (<InputAdornment position="start"><AccountCircle /></InputAdornment>),
                  endAdornment: (<InputAdornment position="end">$/Kg</InputAdornment>)
                }}
                onChange={this.handleChange}
                value={this.state.customerPrice}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                size="small"
                className={classes.margin}
                id="companyPrice"
                label="Company Price"
                InputProps={{
                  startAdornment: (<InputAdornment position="start">
                    <Icon>
                      <img alt="Service" style={{ maxHeight: '1.2em', verticalAlign: 'text-bottom' }} src={customerService} />
                    </Icon>
                  </InputAdornment>),
                  endAdornment: (<InputAdornment position="end">$/Kg</InputAdornment>)
                }}
                onChange={this.handleChange}
                value={this.state.companyPrice}
              />
            </Grid>
            <Grid item xs={12} sm={2} >
              <div style={{ height: '100%', verticalAlign: 'bottom', marginTop: '8px' }}>
                <Button size="small"
                  className={classes.margin}
                  style={{ verticalAlign: 'bottom' }}
                  onClick={this.handleIntuition} >Intuition</Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div style={{ height: '100%', verticalAlign: 'bottom', marginTop: '8px' }}>
                <Button size="small"
                  className={classes.margin}
                  onClick={this.getSuggestRoutes}
                  disabled={this.state.error}>Suggest Routes</Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={8}>
              <IntuitionModal key="IntuitionModal" openIntuition={this.state.openIntuition}
                onValuesChanged={this.handleSubChanged} {...this.getIntuition()} />
            </Grid>
            {/*  <Grid item xs={1}>
            {this.state.loading && <CircularProgress color="secondary" />}
    </Grid> */}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <ItinerariesOverview key="ItinerariesOverview" itineraries={this.state.itineraries} loading={this.state.loading}
              loadingFinished={this.state.loadingFinished} goods={this.state.goods} conditions={this.state.conditions}
              totalWeight={this.state.totalWeight} totalCbm={this.state.totalCbm} totalPieces={this.state.totalPieces}
              customerPrice={this.state.customerPrice} companyPrice={this.state.companyPrice} {...this.getIntuition()}
              appBaseURL={this.props.appBaseURL}
              ucode={this.props.ucode} />
          </Grid>

        </Grid>

      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BookingEClient);