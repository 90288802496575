
/** 
 * sendRMRequest uses xmlhttp.js
 * login processing with cookies 
 * Updated: 2020-09-15
 */

function requestPost(cmd, cls, datatxt, callback, appBaseURL) {

/*   var baseUrl = process.env.APP_BASE_URL_SERVLET ? process.env.APP_BASE_URL_SERVLET : "";
  if (process.env.REACT_APP_BUILD_PROD && process.env.REACT_APP_BUILD_PROD === 'true' && process.env.APP_BASE_URL_SERVLET_PROD) {
    baseUrl = process.env.APP_BASE_URL_SERVLET_PROD;
  } else if (process.env.APP_BASE_URL_SERVLET_LOCAL) {
    baseUrl = process.env.APP_BASE_URL_SERVLET_LOCAL;
  } */

  console.log('requestPost baseUrl: ', appBaseURL)
  // eslint-disable-next-line no-undef
  //console.log('requestPost APP_BASE_URL: ', APP_BASE_URL);

  // eslint-disable-next-line no-undef
  sendRMRequest(cmd, datatxt, callback, cls, appBaseURL);
}

function addCookie(regres) {
  // console.info("addCookie regres: ", regres);
  if (regres && regres.length > 0) {
    var strCookie = regres[0];
    strCookie = strCookie.trim();
    strCookie = strCookie.replace(';', '').replace('\r', '').replace('\n', '');
    // console.info("addCookie: " + strCookie);
    var res = strCookie.split('=');
    if (res && res.length > 1) {
      var name = res[0] === 'SID' ? 'JSESSIONID' : res[0];
      // eslint-disable-next-line no-undef
      setCookie(name, res[1], -1);
    }
  }
}

// eslint-disable-next-line no-unused-vars
function getCookie(name) {
  // console.info("getCookie: ", name);
  var namec = name + "=";
  var ca = document.cookie.split(';');
  // console.info("cookies: ", ca);
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(namec) === 0) {
      return c.substring(namec.length, c.length);
    }
  }
  return "";
}

export default requestPost;
export { addCookie, getCookie };