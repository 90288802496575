import React, { Component } from 'react';

import './App.css';
//import './assets/css/rmco20common.css';
//import './assets/css/rmco20.css';
//import './assets/css/frrmmain.css';

import logo from './assets/images/FRM_logo.png';

import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from "@material-ui/core";
import { themeFR } from './assets/themes/frrmTheme';

//import Drawer from '@material-ui/core/Drawer';
import NavDrawer from './components/nav/nav.drawer';
import { withStyles } from "@material-ui/core/styles";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import grey from "@material-ui/core/colors/grey";
import lightGreen from "@material-ui/core/colors/lightGreen";
import yellow from "@material-ui/core/colors/yellow";
//import { Icon } from '@material-ui/core'

import Routes from './routes/routes.main';
import ConfirmationDialog from './components/ui/ConfirmationDialog';

//console.log('ENV:BUILD_PROD    : ', process.env.REACT_APP_BUILD_PROD);
/// console.log('APP_BASE_URL      : ', process.env.REACT_APP_BUILD_PROD === 'true' ? process.env.REACT_APP_BASE_URL_SERVLET_PROD : process.env.REACT_APP_BASE_URL_SERVLET_LOCAL);
//console.log('URL_SERVLET_PROD  : ', process.env.REACT_APP_BASE_URL_SERVLET_PROD);
//console.log('URL_SERVLET_LOCAL : ', process.env.REACT_APP_BASE_URL_SERVLET_LOCAL);
//console.log('URL_SERVLET_TC    : ', process.env.REACT_APP_BASE_URL_SERVLET_TC);
//console.log('URL_SERVLET       : ', process.env.REACT_APP_BASE_URL_SERVLET);
/// console.log('CMD_GET_PICK_LIST : ', process.env.REACT_APP_CMD_GET_PICK_LIST);

// eslint-disable-next-line no-unused-vars
var APP_BASE_URL = process.env.REACT_APP_BUILD_PROD === 'true' ? process.env.REACT_APP_BASE_URL_SERVLET_PROD : process.env.REACT_APP_BASE_URL_SERVLET_LOCAL;
var APP_UPLOAD_URL = process.env.REACT_APP_BUILD_PROD === 'true' ? process.env.REACT_APP_UPLOAD_URL_SERVLET_PROD : process.env.REACT_APP_UPLOAD_URL_SERVLET_LOCAL;
var APP_DOWNLOAD_URL = process.env.REACT_APP_BUILD_PROD === 'true' ? process.env.REACT_APP_DOWNLOAD_URL_SERVLET_PROD : process.env.REACT_APP_DOWNLOAD_URL_SERVLET_LOCAL;
var CMD_GET_PICK_LIST = process.env.REACT_APP_CMD_GET_PICK_LIST;
var CMD_GET_PICK_LIST_XML = process.env.REACT_APP_CMD_GET_PICK_LIST_XML;

console.log('APP_BASE_URL      : ', APP_BASE_URL);
console.log('CMD_GET_PICK_LIST : ', CMD_GET_PICK_LIST_XML);

/* const styles = {
  navBar: {'top': AppBar.height}
} */

const styles = theme => ({
  root: {
    flexGrow: 1,
    fontFamily: "Roboto"
  },
  drawerPaper: { width: 'inherit', 'top': AppBar.height },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#112649',
  },

  navBar: { 'top': AppBar.height },
});

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      islogin: false,
      username: "",
      ucode: "",
      appBaseURL: APP_BASE_URL,
      appUploadURL: APP_UPLOAD_URL,
      appDownloadURL: APP_DOWNLOAD_URL,
      appCmdGetPickListXml: CMD_GET_PICK_LIST_XML,
      appCmdGetPickList: CMD_GET_PICK_LIST,
      appbarh: '120',
      openConfirmation: false
    };
    // this.history = createBrowserHistory();
    this.onLoginChanged = this.onLoginChanged.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onConfirmationChanged = this.onConfirmationChanged.bind(this);
  }

  componentDidMount() {

    const script = document.createElement("script");
    script.async = true;
    script.src = "../xml.js";
    //For head
    document.head.appendChild(script);
    const script2 = document.createElement("script");
    script2.async = true;
    script2.src = "../xmlhttp.js";
    document.head.appendChild(script2);

    // For body
    //document.body.appendChild(script);

    this.setState({ appbarh: AppBar.height });
  }

  onLoginChanged = (values) => {
    this.setState({
      islogin: (!values || values.login == null) ? false : values.login,
      username: values.username,
      ucode: values.ucode
    }, () => {
      console.log("app onLoginChanged isLogin:", values.login, " ucode: ", this.state.ucode);
    });
  };

  onPageChanged = () => {
    if (this.state.open) {
      this.handleToggle();
    }
  }

  onLogout(e) {
    this.setState({ openConfirmation: true },
      () => { console.log("Open confirmation !") });
  }

  onConfirmationChanged(val) {
    this.setState({ openConfirmation: val.openConfirmation },
      () => {
        if (val.confirmationOk) {
            window.location.href="/";
        }
      }
    );
  }



  handleToggle = () => this.setState({ open: !this.state.open });

  render() {

    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={themeFR}>
        <BrowserRouter basename="/">
          <div className={classes.root}>

            <AppBar position="static">
              <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.handleToggle} >
                  <MenuIcon />
                </IconButton>
                <div><img src={logo} alt="Logo" style={{ 'maxWidth': '100%', 'maxHeight': '73px' }} /></div>
                <div style={{ marginLeft: "auto" }}>
                  {
                    this.state.islogin ?
                      (
                        <>
                          <div><IconButton><PersonOutlineIcon style={{ color: lightGreen[500] }} /><ExitToAppIcon onClick={this.onLogout} style={{ color: yellow[600] }} /></IconButton></div>
                          <div style={{ textAlign: 'center' }}>{this.state.username}</div>
                        </>
                      )
                      :
                      (<PersonOutlineIcon style={{ color: grey[500] }} />)
                  }
                  {
                    this.state.openConfirmation &&
                    (<ConfirmationDialog openConfirmation={this.state.openConfirmation}
                      confirmationType={"logout"}
                      confirmationTitle={"LogOut"}
                      confirmationText={"Do you really wat to logout?"}
                      onConfirmationChanged={this.onConfirmationChanged} />
                    )}

                </div>
              </Toolbar>
            </AppBar>

            <NavDrawer islogin={this.state.islogin} open={this.state.open} appbarh={this.state.appbarh} ucode={this.state.ucode} />

            <div className="App-intro">{
              <Routes {...this.props} onPageChanged={this.onPageChanged}
                onLoginChanged={this.onLoginChanged}
                appBaseURL={this.state.appBaseURL}
                appUploadURL={this.state.appUploadURL}
                appDownloadURL={this.state.appDownloadURL}
                appCmdGetPickList={this.state.appCmdGetPickList}
                ucode={this.state.ucode} />}

            </div>


          </div>
        </BrowserRouter>

      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles, { withTheme: true })(App);