/** 
 * Updated: 2020-12-12
 */

import { Box, CircularProgress, FormLabel, Grid, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";
import BlockHeader from '../components/ui/BlockHeader';
import ChartsBar from "../components/charts/charts.bar";
import ChartsBarsComposed from "../components/charts/charts.bars_composed";
// import ChartsBars2Percent from "../components/charts/chrats.bar_2_perc";
import ChartsBarNegative from "../components/charts/charts.bars_negative";
import getKPIdata from "../service/kpi.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChartsValuesCompare from "../components/charts/charts.values.compare";
import ChartsValueMargin from "../components/charts/charts.value.margin";
import ChartsBarSynch from "../components/charts/carts.bar.synch";



class KpiRevenue extends Component {

    max_alert_items = 5;

    months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            revenueYTD: [],
            totalRevenueYTD: [],
            revenueYTDOD: [],
            ytdMonth: this.month[(new Date()).getMonth()],
            chart_RevenueYTD: {
                headerText: '', mainValue: '', secondHeaderText: '', secondValue: '',
                dataKey: 'month', dataValueKey: 'fare', dataValueColor: '#8884d8',
                data: [], dataRefs: []
            },
            chart_NetProfit: {
                headerText: '', mainValue: '', secondHeaderText: '',
                secondValue: '', secondValueText: '',
                dataKey: 'name', dataValueKey: 'value', dataValueColor: '#8884d8',
                data: []
            },
            chart_RevenueMonthlyCum: {
                headerText: '', mainValue: '', secondHeaderText: '',
                secondValue: '', secondValueText: '',
                dataKey: 'month',
                data: [], valuesKeys1: [], valuesKeys2: []
            },
            chart_MonthlyRevenueTarget : {
                headerText: '', secondHeaderText: '',
                dataKey: 'month',
                data: [], valuesKeys: []
            },
            chart_HighLowOD: {
                headerText: '', mainValue: '', secondHeaderText: '',
                secondValue: '', secondValueText: '',
                dataKey: 'od', data: []
            },
            chart_HighLowODPlan: {
                headerText: '', mainValue: '', secondHeaderText: '',
                secondValue: '', secondValueText: '',
                dataKey: 'od', data: []
            },

            dfod_fare: [],
            dfod_plandiff: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeYTDMonth = this.handleChangeYTDMonth.bind(this);
    }

    componentDidMount() {
        if (this.props.onPageChanged) {
            this.props.onPageChanged();
        }

        this.loadDashboardData();
    }

    loadDashboardData() {
        var data = { user: this.props.ucode, cmd: 'RevenueYTD', type: 'RevenueYTD', ytdMonth: this.state.ytdMonth };
        this.setState({ loading: true },
            () => { getKPIdata(data, this.callbackDashboardData, this.props.appBaseURL) });
        ;
    }

    callbackDashboardData = (response) => {

        console.log('callbackDashboardData: ', response);
        var dataList = [];
        if (response?.XMLResponse?.Group) {
            dataList = response.XMLResponse.Group.map((group) => {
                var rows = [];
                if (Array.isArray(group.Row)) {
                    rows = group.Row.map((row) => { return row._attributes; });
                } else {
                    rows = [].concat(group.Row._attributes);
                }
                const result = Object.assign({}, group._attributes, { rows: rows });
                return result;
            });
        }
        console.log('DashboardData converted: ', dataList);

        // post-processing
        var revenueYTD = [];
        var totalRevenueYTD = [];
        var revenueYTDOD = [];
        var idx, len;
        var i, length;
        try {
            for (idx = 0, len = dataList.length; idx < len; idx++) {
                var item = dataList[idx];
                var name = item.name;
                switch (name) {
                    case 'RevenueYTD':
                        for (i = 0, length = item.rows.length; i < length; i++) {
                            item.rows[i].fare = parseFloat(item.rows[i].fare);
                            item.rows[i].fareCumulative = parseFloat(item.rows[i].fareCumulative);
                            item.rows[i].expected = parseFloat(item.rows[i].expected);
                            item.rows[i].expectedCumulative = parseFloat(item.rows[i].expectedCumulative);
                            item.rows[i].expenses = parseFloat(item.rows[i].expenses);
                            item.rows[i].expensesCumulative = parseFloat(item.rows[i].expensesCumulative);
                            item.rows[i].target = parseFloat(item.rows[i].target);
                            item.rows[i].targetCumulative = parseFloat(item.rows[i].targetCumulative);
                        }
                        revenueYTD = [...item.rows];
                        break;
                    case 'TotalRevenueYTD':
                        for (i = 0, length = item.rows.length; i < length; i++) {
                            item.rows[i].fare = parseFloat(item.rows[i].fare);
                            item.rows[i].expected = parseFloat(item.rows[i].expected);
                            item.rows[i].expenses = parseFloat(item.rows[i].expenses);
                            item.rows[i].target = parseFloat(item.rows[i].target);
                        }
                        totalRevenueYTD = [...item.rows];
                        break;
                    case 'RevenueYTDOD':
                        for (i = 0, length = item.rows.length; i < length; i++) {
                            item.rows[i].fare = parseFloat(item.rows[i].fare);
                            item.rows[i].expected = parseFloat(item.rows[i].expected);
                            item.rows[i].expenses = parseFloat(item.rows[i].expenses);
                            item.rows[i].target = parseFloat(item.rows[i].target);

                            item.rows[i]['fareTargetPc'] = this.roundToN(item.rows[i].target !== 0 ? item.rows[i].fare / item.rows[i].target : 1.0, 3);
                            item.rows[i]['fareExpectedPc'] = this.roundToN(item.rows[i].expected !== 0 ? item.rows[i].fare / item.rows[i].expected : 1.0, 3);
                            item.rows[i]['fareTargetDiff'] = item.rows[i].target !== 0 ? item.rows[i].fare - item.rows[i].target : item.rows[i].fare;
                            item.rows[i]['fareExpectedDiff'] = item.rows[i].expected !== 0 ? 1.0 * item.rows[i].fare - item.rows[i].expected : item.rows[i].fare;
                            item.rows[i]['od'] = item.rows[i].origin + "-" + item.rows[i].destination;
                        }
                        revenueYTDOD = [...item.rows];
                        break;
                    default:
                        break;
                }
            }
        } catch (ex) {
            console.log('callbackDashboardData: ', ex);
        }

        // N vals compare chart
        let chart_RevenueYTD = {
            headerText: 'Revenue', mainValue: '', secondHeaderText: '', secondValue: '',
            dataKey: 'month', dataValueKey: 'fare', dataMax: 0, dataValueColor: '#8884d8',
            data: [], dataRefs: [], legend: []
        };

        let chart_NetProfit = {
            headerText: 'Net Profit', mainValue: '', secondHeaderText: '',
            secondValue: '', secondValueText: '',
            dataKey: 'name', dataValueKey: 'value', dataValueColor: '#8884d8',
            data: []
        };

        let chart_RevenueMonthlyCum = {
            headerText: 'Revenue Monthly', mainValue: '', secondHeaderText: '',
            secondValue: '', secondValueText: '',
            dataKey: 'month',
            data: [], valuesKeys1: [], valuesKeys2: []
        };

        let chart_MonthlyRevenueTarget = {
            headerText: 'Monthly Revenue Target', secondHeaderText: '',
            dataKey: 'month',
            data: [], valuesKeys: []
        };

        if (totalRevenueYTD.length > 0) {
            // chart_RevenueYTD
            chart_RevenueYTD.headerText = 'Revenue ';
            chart_RevenueYTD.mainValue = Math.round(totalRevenueYTD[0].fare / 1000).toString() + "k" +
                '  (' + (totalRevenueYTD[0].fare < totalRevenueYTD[0].target ? '' : '+')
                + this.roundToN((totalRevenueYTD[0].fare - totalRevenueYTD[0].target) / totalRevenueYTD[0].target * 100, 1).toString() + '%)';
            chart_RevenueYTD.secondHeaderText = 'Target ';
            chart_RevenueYTD.secondValue = Math.round(totalRevenueYTD[0].target / 1000).toString() + "k";

            chart_RevenueYTD.data = [...totalRevenueYTD];
            chart_RevenueYTD.dataRefs.push({ value: totalRevenueYTD[0].target, name: 'target', color: '#ff0000' });
            chart_RevenueYTD.dataRefs.push({ value: totalRevenueYTD[0].expected, name: 'expected', color: '#00ff00' });

            chart_RevenueYTD.legend.push({ id: 'fare', type: "square", value: 'fare ' + Math.round(totalRevenueYTD[0].fare / 1000).toString() + "K", color: '#8884d8', });
            chart_RevenueYTD.legend.push({ id: 'target', type: "square", value: 'target ' + Math.round(totalRevenueYTD[0].target / 1000).toString() + "K", color: '#ff0000', });
            chart_RevenueYTD.legend.push({ id: 'expected', type: "square", value: 'expected ' + Math.round(totalRevenueYTD[0].expected / 1000).toString() + "K", color: '#00ff00', });

            chart_RevenueYTD.dataMax = Math.max(totalRevenueYTD[0].fare, totalRevenueYTD[0].target, totalRevenueYTD[0].expected, totalRevenueYTD[0].expenses);

            // chart_NetProfit
            /* 
                Net profit = Sales revenue - Total costs
                Net profit margin = (Net profit / Revenues) * 100
            */

            let netProfit = Math.round(totalRevenueYTD[0].fare - totalRevenueYTD[0].expenses);
            let netProfitMargin = this.roundToN((totalRevenueYTD[0].fare - totalRevenueYTD[0].expenses) / totalRevenueYTD[0].fare * 100, 1);

            chart_NetProfit.headerText = 'Net Profit ';
            chart_NetProfit.mainValue = Math.round(netProfit / 1000).toString() + "k";

            let data = [];
            data.push({ name: 'profit', value: netProfit, color: '#4169E1' });
            data.push({ name: 'expenses', value: totalRevenueYTD[0].expenses, color: '#DCDCDC' });
            chart_NetProfit.data = [...data];

            chart_NetProfit.secondHeaderText = 'Net Profit Margin';
            chart_NetProfit.secondValue = netProfitMargin;
            chart_NetProfit.secondValueText = netProfitMargin.toString() + '%';

        }

        if (revenueYTD.length > 0) {
            chart_RevenueMonthlyCum.headerText = "Monthly Revenue";
            chart_RevenueMonthlyCum.secondHeaderText = "Monthly YTD Revenue";
            //  data: [], valuesKeys1: [], valuesKeys2: []
            chart_RevenueMonthlyCum.data = [...revenueYTD];
            chart_RevenueMonthlyCum.valuesKeys1.push({ name: 'fare', color: '#1C75BC' });
            chart_RevenueMonthlyCum.valuesKeys1.push({ name: 'target', color: '#84AEDC' });
            chart_RevenueMonthlyCum.valuesKeys1.push({ name: 'expected', color: '#9CD5F4' });
            chart_RevenueMonthlyCum.valuesKeys2.push({ name: 'fareCumulative', color: '#1C75BC' });
            chart_RevenueMonthlyCum.valuesKeys2.push({ name: 'targetCumulative', color: '#84AEDC' });
            chart_RevenueMonthlyCum.valuesKeys2.push({ name: 'expectedCumulative', color: '#9CD5F4' });

            chart_MonthlyRevenueTarget.headerText = "Monthly Revenue-Target";
            chart_MonthlyRevenueTarget.data = [...revenueYTD];
            chart_MonthlyRevenueTarget.valuesKeys.push({ name: 'target', color: '#ff000077' });
            chart_MonthlyRevenueTarget.valuesKeys.push({ name: 'fare', color: '#413ea0' });
        }

       
        //
        let chart_HighLowOD = {
            headerText: 'High/Low OD YTD Revenue', mainValue: '', secondHeaderText: '',
            secondValue: '', secondValueText: '',
            dataKey: 'od', dataValueKey: 'fare', data: []
        };

        let chart_HighLowODPlan = {
            headerText: 'High/Low OD YTD Plan Implementation', mainValue: '', secondHeaderText: '',
            secondValue: '', secondValueText: '',
            dataKey: 'od', dataValueKey: 'fareTargetDiff', data: []
        };

        if( revenueYTDOD.length > 0 ) {
            let odf_sort = revenueYTDOD.slice().sort((a,b) => { return -1 * (a.fare - b.fare)});
            let odp_sort = revenueYTDOD.slice().sort((a,b) => { return -1 * (a.fareTargetDiff - b.fareTargetDiff)});
            if ( odf_sort.length > (this.max_alert_items * 2) ) {

                chart_HighLowOD.data.push(...odf_sort.slice(0, this.max_alert_items));
                chart_HighLowOD.data.push(...odf_sort.slice(-this.max_alert_items));
                chart_HighLowODPlan.data.push(...odp_sort.slice(0, this.max_alert_items));
                chart_HighLowODPlan.data.push(...odp_sort.slice(-this.max_alert_items));
            }
            else {
                chart_HighLowOD.data = odf_sort.slice();
                chart_HighLowODPlan.data = odp_sort.slice();
            }
        }

        console.log('chart_RevenueYTD: ', chart_RevenueYTD);
        console.log('chart_NetProfit: ', chart_NetProfit);
        console.log('chart_RevenueMonthlyCum: ', chart_RevenueMonthlyCum);
        console.log('this.max_alert_items: ', this.max_alert_items);
        console.log('chart_HighLowOD: ', chart_HighLowOD);
        console.log('chart_HighLowODPlan: ', chart_HighLowODPlan);


        this.setState({
            loading: false,
            revenueYTD: [...revenueYTD],
            totalRevenueYTD: [...totalRevenueYTD],
            revenueYTDOD: [...revenueYTDOD],
            chart_RevenueYTD: chart_RevenueYTD,
            chart_NetProfit: chart_NetProfit,
            chart_RevenueMonthlyCum: chart_RevenueMonthlyCum,
            chart_MonthlyRevenueTarget: chart_MonthlyRevenueTarget,
            chart_HighLowOD: chart_HighLowOD,
            chart_HighLowODPlan: chart_HighLowODPlan
        });
    }

    /**  */
    roundToN(num, precision) {
        return +(Math.round(num + "e+" + precision) + "e-" + precision);
    };

    handleChange(e, value) {
        console.log('handleChange', e);
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleChangeYTDMonth(e, value) {
        // console.log('handleChangeYTDMonth', e);
        this.setState({
            ytdMonth: value
        }, () => { this.loadDashboardData(); });
    }

    render() {
        return (
            <>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <FormLabel style={{ marginRight: '15px' }}>{"YTD Month"}</FormLabel>
                            <Autocomplete id="ytdMonth"
                                value={this.state.ytdMonth}
                                autoComplete={true}
                                autoSelect={true}
                                options={this.month}
                                size="small"
                                style={{ margin: 0 }}
                                renderInput={(params) => <TextField {...params} variant="standard" />}
                                onChange={this.handleChangeYTDMonth}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>{"Revenue Analysis from Jan to " + this.state.ytdMonth + " " + (new Date()).getFullYear()}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <BlockHeader headertext={"Revenue YTD"}></BlockHeader>                        
                        <ChartsValuesCompare data={this.state.chart_RevenueYTD}
                            width={370} height={80}></ChartsValuesCompare>                        
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <BlockHeader headertext={"Value and Margin"}></BlockHeader>
                        <ChartsValueMargin data={this.state.chart_NetProfit} width={160} height={160}></ChartsValueMargin>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <BlockHeader headertext={"Synch Charts Monthly"}></BlockHeader>
                        <ChartsBarSynch data={this.state.chart_RevenueMonthlyCum} width={370} height={120}></ChartsBarSynch>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        {/*<BlockHeader headertext={"Revenue Monthly"}></BlockHeader>*/}
                        <BlockHeader headertext={"Show Value vs target step"}></BlockHeader>
                        <ChartsBarsComposed data={this.state.chart_MonthlyRevenueTarget} dataKey="month" width={370} height={260}></ChartsBarsComposed>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <BlockHeader headertext={"5 High/Low items"}></BlockHeader>
                        <ChartsBar data={this.state.chart_HighLowOD} dataKey="od" dataValueKey="fare" width={370} height={260}></ChartsBar>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <BlockHeader headertext={"5 High/Low Diffs"}></BlockHeader>
                        <ChartsBarNegative data={this.state.chart_HighLowODPlan} dataKey="od" dataValueKey="fareTargetDiff" width={370} height={260}></ChartsBarNegative>
                    </Grid>
                </Grid>
                {
                    this.state.loading &&
                    <div style={{ position: 'absolute', top: '50%', left: '50%', marginRight: '-50%', transform: 'translate(-50%, -50%)' }}>
                        <CircularProgress color="secondary" />
                    </div>
                }

            </>
        );
    }
}

export default KpiRevenue;