/**
 * processing user actions
 * Updated: 2020-09-15
 */

import requestPost, { addCookie } from './api.service.xmlhttp';

//var convert = require('xml-js');


/**
 * 
 * @param {*} uid 
 * @param {*} password 
 * @param {*} cbUser 
 * @param {*} appBaseURL 
 */
function login(uid, password, cbUser, appBaseURL) {

    function callbackLogin(response) {

        // console.log("callbackLogin: ", response)
        // eslint-disable-next-line no-undef
        var respXml = new XmlNode("", "");
        if (response && response.length > 0) {
            // console.info("callbackLogin: ", response);
            respXml.parse(response);
        }

        // eslint-disable-next-line no-undef
        clearCookie("ucode");
        // eslint-disable-next-line no-undef
        clearCookie("username");
        let login = respXml.getAttributeValue("login");
        if (login === "true") {
            // eslint-disable-next-line no-undef
            // var sid = getCookie("SID");
            // console.log("sid: " + sid)
            // eslint-disable-next-line no-undef
            setCookie("session", "rmw21");
            var regex = /(\s)+SID=[a-zA-Z0-9]+(;|\s)/;
            var result = regex.exec(response);
            // console.log("result:" + result);
            addCookie(result);
            var regex2 = /(\s)+ucode=[a-zA-Z0-9]+(;|\s)/;
            var result2 = regex2.exec(response);
            // console.log("result2:" + result2);
            addCookie(result2);
            var regex3 = /(\s)+username=[a-zA-Z0-9]+(;|\s)/;
            var result3 = regex3.exec(response);
            // console.log("result3:" + result3);
            addCookie(result3);
        }

        if (cbUser) {
            cbUser(respXml);
        }
    }


    if (!(uid && uid.length > 0 && password && password.length > 0)) {
        return { "error": "Wrong credentials." };
    }

    let CMD = 'User.login';
    let category = 'EB';
    let xml = '<XMLRequest cmd="' + CMD + '" ';
    xml += 'category="' + category + '" ';
    xml += 'username="' + uid + '" ';
    xml += 'password="' + password + '" ';
    xml += ' />';

    // eslint-disable-next-line no-undef
    console.log('login APP_BASE_URL: ', appBaseURL);

    requestPost('&tenant=*', '', xml, callbackLogin, appBaseURL);
}


export default login;
