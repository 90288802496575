/** 
 * 
 * ComposedChart
 * 
 * Updated: 2020-12-26
 */

import { Typography } from "@material-ui/core";
import React, { Component } from "react";

import { ComposedChart, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import BoxKpi from '../ui/ui.box.kpi';

class ChartsBarsComposed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            width: props.width,
            height: props.height
        };
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
    }


    handleChange(event) {
    }


    render() {
        return (
            <BoxKpi>
                <Typography variant="h5" gutterBottom>{this.props.data.headerText}</Typography>
                <ComposedChart
                    viewBox="0 0 {this.state.width} {this.state.height}"
                    preserveAspectRatio="xMidYMid meet"

                    width={this.state.width}
                    height={this.state.height}
                    data={this.props.data.data}
                    margin={{
                        top: 20, right: 20, bottom: 20, left: 20,
                    }}
                >
                    <CartesianGrid fill="#fff" stroke="#f5f5f5" />
                    <XAxis dataKey={this.props.dataKey} height={60} />
                    <YAxis tickFormatter={tick => { return Math.round(tick / 1000).toString() + "k"; }} />
                    <Tooltip />
                    <Legend />
                    {
                        this.props.data.valuesKeys.map((item, index) =>
                        (index === 0 ?
                            <Area dataKey={item.name} type="step" fill={item.color} stroke={item.color} />
                            :
                            <Bar dataKey={item.name} barSize={20} fill={item.color} />
                        ))
                    }

                    {/* <ReferenceLine  x={2081563} stroke="#ff0000" />
                <ReferenceLine  x={3081563} stroke="#00ff00"/>
                <Line type="monotone" dataKey="uv" stroke="#ff7300" />*/}
                    {/* <Scatter dataKey="cnt" fill="red" /> */}
                </ComposedChart>
            </BoxKpi>
        );
    }
}

export default ChartsBarsComposed;