/**
 * Updated: 2020-09-15
 */

import requestPost from './api.service';

export default function suggestCargoRoutes(data, cbBooking, appBaseURL) {

    var convert = require('xml-js');

    let requiredFields = ['origin', 'destination', 'dateFrom', 'dateTo', 'conditions', 'goods', 'totalPieces', 'totalWeight', 'totalCbm'];

    /** */
    function callbackCargoRoutes(response) {
        // console.log("CargoRoutes response: ", response);

        var respJson = {};
        if (response && response.length > 0) {
            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }

        if (cbBooking) {
            cbBooking(respJson);
        }
    }

    for (let i = 0; i < requiredFields.length; i++) {
        let key = requiredFields[i];
        if (!data[key] || data[key] == null || data[key].length === 0) {
            let errVal = "No value of " + key;
            let resp = { errorMessage: errVal };
            cbBooking(resp);
            return;
        }
    }

    var intuition = {};
    Object.keys(data).forEach( key => {
        if(key.startsWith("intuition_")) {
            intuition[key] = data[key]
        }
    });

    var intuitionStr = JSON.stringify(intuition);
    intuitionStr = encodeURIComponent(intuitionStr); 

    var parameters = {};
    parameters['customerPrice'] = data['customerPrice'];
    parameters['companyPrice'] = data['companyPrice'];
    var parametersStr = JSON.stringify(parameters);
    parametersStr = encodeURIComponent(parametersStr); 

    let CMD = 'EBookingEngine.suggestCargoItinerary'; 
    let blank = '';
    let xml = '<XMLRequest cmd="' + CMD + '" ';
    xml += 'showCargo="false" code="short" version="nested" ';
    xml += ' >';
    xml += "<Row "
    xml += 'code="0" ';
    xml += 'origin="' + data.origin + '" ';
    xml += 'destination="' + data.destination + '" ';
    xml += 'departureAfter="' + data.dateFrom.toISOString().substring(0, 10) + '" ';
    xml += 'arrivalBefore="' + data.dateTo.toISOString().substring(0, 10) + '" ';
    xml += 'minHours="' + data.minHours + '" ';
    xml += 'maxHours="' + data.maxHours + '" ';
    xml += 'saleDate="' + data.saleDate.toISOString().substring(0, 10) + '" ';
    xml += 'manifestCode="' + data.conditions + '" ';
    xml += 'goods="' + data.goods + '" ';
    xml += 'weight="' + data.totalWeight + '" ';
    xml += 'kg="' + data.totalWeight + '" ';
    xml += 'volume="' + data.totalCbm + '" ';
    xml += 'cbm="' + data.totalCbm + '" ';
    xml += 'pcs="' + data.totalPieces + '" ';
    xml += 'agentCode="' + blank + '" ';
    xml += 'agentCodeName="' + blank + '" ';
    xml += 'shipperCode="' + blank + '" ';
    xml += 'shipperCodeName="' + blank + '" ';
    xml += 'minRate="' + blank + '" ';
    xml += 'requestUser="' + blank + '" ';
    xml += 'intuition="' + intuitionStr + '" ';
    xml += 'parameters="' + parametersStr + '" ';
    xml += " >"
    xml += "</Row>"
    xml += '</XMLRequest>';

    requestPost('', '', xml, callbackCargoRoutes, appBaseURL);
}

/** */
function setConfirmed(data, cbConfirmed, appBaseURL, ucode) {

    var convert = require('xml-js');

    /** */
    function callbackConfirmed(response) {
        var respJson = {};
        if (response && response.length > 0) {
            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }

        if (cbConfirmed) {
            cbConfirmed(respJson);
        }
    }

    var intuition = {};
    Object.keys(data).forEach( key => {
        if(key.startsWith("intuition_")) {
            intuition[key] = data[key]
        }
    });
    var intuitionStr = JSON.stringify(intuition);
    intuitionStr = encodeURIComponent(intuitionStr); 

    var parameters = {};
    parameters['customerPrice'] = data['customerPrice'];
    parameters['companyPrice'] = data['companyPrice'];
    var parametersStr = JSON.stringify(parameters);
    parametersStr = encodeURIComponent(parametersStr); 

    var CMD = 'EBookingEngine.acceptPrice';
    var xml = '<XMLRequest cmd="' + CMD + '" ';
    xml += ' uuid="' + data.uuid + '" ';
    xml += ' uuid_booking_action="' + data.uuid_booking_action + '" ';
    xml += ' requestUser="' + ucode + '" ';
    xml += ' intuition="' + intuitionStr + '" ';
    xml += ' parameters="' + parametersStr + '" ';
    xml += ' />';

    // console.log('setConfirmed: ', xml);

    requestPost('', '', xml, callbackConfirmed, appBaseURL);
}


/** */
function getDealsHistory(data, cbBookingHistory, appBaseURL) {

    var convert = require('xml-js');

    /** */
    function callbackDealsHistory(response) {
        var respJson = {};

        if (response && response.length > 0) {
            respJson = convert.xml2js(response, { compact: true, spaces: 2 });
        }

        if (cbBookingHistory) {
            cbBookingHistory(respJson);
        }

    }

    // <XMLRequest cmd="EBookingEngine.getDeals"  requestUser="demo"  ></XMLRequest>
    let CMD = 'EBookingEngine.getDeals';
    let xml = '<XMLRequest cmd="' + CMD + '" ';
    xml += 'requestUser="' + data.user + '" ';
    xml += '</XMLRequest>';

    requestPost('', '', xml, callbackDealsHistory, appBaseURL);

}

export { getDealsHistory, setConfirmed };
